import {
  MagnifyingGlassIcon,
  HomeIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import MenuBar from "shared/MenuBar/MenuBar";


interface NavItem {
  name: string;
  link?: string;
  icon: any;
}

const NAV: NavItem[] = [
  {
    name: "Home",
    link: "/",
    icon: HomeIcon,
  },
  {
    name: "Explore",
    link: "/listing?query=&type=1",
    icon: MagnifyingGlassIcon
  },
  { 
    name: "My Account",
    link: "/dashboard",
    icon: UserCircleIcon
  },
  {
    name: "Log in",
    link: "/login",
    icon: UserCircleIcon,
  },
  {
    name: "Menu",
    icon: MenuBar,
  },
  
];

const FooterNav = () => {
  const location = useLocation();
  const cookies = document.cookie.split('; ');
  const isLoggedIn = cookies.some((cookie) => cookie.trim().startsWith('userId='));


  return (
    <div
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center">
        {NAV.map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            (item.name !== "My Account" || isLoggedIn) && (
              item.name !== "Log in" || !isLoggedIn) && (
              <Link
                key={index}
                to={item.link}
                className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                  active ? "text-neutral-900 dark:text-neutral-100" : ""
                }`}
              >
                <item.icon
                  className={`w-6 h-6 ${active ? "text-red-600" : ""}`}
                />
                <span className="text-[11px] leading-none mt-1">{item.name}</span>
              </Link>
            )
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              <item.icon iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
