import { FC, useState, useEffect } from "react";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ListingMapFilters from "./ListingMapFilters";
import { FrontendData } from "../../types/types";


export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => { 
  const [frontendData, setFrontendData] = useState<FrontendData | null>(null);
  useEffect(() => {
    const getCookie = (name: string): string | null => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${name}=`)) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };

    const fetchFrontendData = () => {
      const frontendDataCookie = getCookie('frontendData');
      if (frontendDataCookie) {
        try {
          const parsedFrontendData: FrontendData = JSON.parse(frontendDataCookie);
          setFrontendData(parsedFrontendData);

        } catch (error) {
          console.error('Error parsing frontendData cookie:', error);
        }
      } else {
        console.log('Cookie not found or has no value');
      }
    };

    fetchFrontendData();
  }, []);

  const location = useLocation();


  const params = new URLSearchParams(location.search);

  var type: number | null;
  var query = params.get("query");
  type = Number(params.get("type"));
  var open = (params.get("open"))
  
  if (query === undefined || query === "" || query === 'null') {
    query = null;
  } else {

  }
  
  if (type === undefined || type === null) {
    type = null;
  } else {
 
  }

  return (
    <>
      <div className={`nc-ListingStayMapPage relative ${className}`} data-nc-id="ListingStayMapPage">
        <Helmet>
          <title>{`Feelaxo - venues in ${frontendData?.city_name}`}</title>
        </Helmet>
        {/* <BgGlassmorphism className="fixed" /> */}

        <ListingMapFilters />

        <div className="lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none bg-gradient-to-r from-pink-50 to-purple-100">
          <SectionGridHasMap query={query} type={type} open={open} />
        </div>
      </div>
    </>
  )
};

export default ListingStayMapPage;
