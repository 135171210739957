import React, { useState, useEffect } from "react";
import StartRating from "components/StartRating/StartRating";
import axios from "axios";
import {FrontendData} from "../../types/types";

interface Business {
    id: number;
    business_name: string;
    address: string;
    rating: number;
    total_rating: number;
    thumbnail: string;
}



const Wishlist = () => {
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [cityData, setCityData] = useState<FrontendData | null>(null);


    useEffect(() => {
        getCityDataFromCookie();
    }, []);

    // Function to get cityData from cookie
    const getCityDataFromCookie = () => {
        const getCookie = (cookieName: string): string | null => {
            const name = `${cookieName}=`;
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookieArray = decodedCookie.split(";");

            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i].trim();
                if (cookie.indexOf(name) === 0) {
                    return cookie.substring(name.length, cookie.length);
                }
            }

            return null;
        };

        const frontendDataCookie: FrontendData | null = JSON.parse(getCookie("frontendData") || "null");

        if (frontendDataCookie) {
            setCityData(frontendDataCookie);
        }
    };

    const makeSEOUrl = async (businessName: string, businessId: number, businessType: number) => {
        let url = `/venue/`;
        let bType: string = '';
      
        if (businessType === 1) {
          bType = "spa";
        } else if (businessType === 2) {
          bType = "salon";
        } else if (businessType === 3) {
          bType = "wellness";
        } else {
          try {
            const response = await axios.post("/api/business/post/business/type", { businessId });
            console.log(response.data);
            bType = response.data;
          } catch (error) {
            console.error(error);
            // Handle error gracefully
          }
        }
      
      
        const sanitizedCityName = cityData?.city_name.replace(/\s/g, "-");
      
        const sanitizedBusinessName = businessName.replace(/\s/g, "-");
      
        url = `${url}${bType}/${sanitizedCityName}/${sanitizedBusinessName}?id=${businessId}`;
      
        return url;
      };
    


    const readCookie = (cookieName: string): string | null => {
        const nameEQ = cookieName + "=";
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === " ") {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }
        return null;
    };

    const userId = readCookie("userId");

    useEffect(() => {
        axios.post("/api/customer/wishlist", {
            user_id: userId
        })
            .then((response) => {
                setBusinesses(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [userId]);

    const handleImageError = (index: number) => {
        const updatedBusinesses = [...businesses];
        updatedBusinesses[index].thumbnail = 'https://nestcraft.sirv.com/Feelaxo/placeholder-small.png';
        setBusinesses(updatedBusinesses);
    };


    function truncateString(inputString: string): string {
        if (inputString.length <= 5) {
            return inputString;
        } else {
            return inputString.substring(0, 10) + '...';
        }
    }
    

    return (
        <div className="wishlistContainer mt-10 mb-20">
            <h1 className="text-center text-2xl font-bold p-5">Wishlist</h1>
            <div className="nc-wishlistpage relative container">
                
                {businesses.length === 0 ? (
                    <div className="flex justify-center">Your wishlist is empty click on type Like Button to save venue in </div>
                ) : (
                    <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {businesses.map((business, index) => (
                            <React.Fragment key={index}>
                                <a 
                                    href="#" 
                                    onClick={(e) => {
                                    e.preventDefault();
                                    makeSEOUrl(business.business_name, business.id, 0).then((url) => {
                                        window.location.href = url;
                                    }).catch((error) => {
                                        console.error("Error creating SEO URL:", error);
                                        // Handle error gracefully
                                    });
                                    }} 
                                    className="block" 
                                    key={business.id}
                                >
                                    <div className="w-full md:w-auto">
                                        <div className="image-container h-44 rounded-t-xl">
                                            <img
                                                src={business.thumbnail}
                                                alt="Business Image"
                                                className="w-full h-full object-cover rounded-t-xl"
                                                onError={() => handleImageError(index)}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-4 space-y-4 border rounded-b-xl">
                                        <div className="space-y-2">
                                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                                <span className="">{truncateString(business.address)}</span>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <h2 className="font-medium capitalize text-lg">
                                                    <span className="line-clamp-1">{business.business_name}</span>
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                                        <div className="flex justify-between items-center">
                                            <StartRating point={business.rating} reviewCount={business.total_rating} />
                                        </div>
                                    </div>
                                </a>
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Wishlist;
