import React, { useState } from 'react';

const FaqPage: React.FC = () => {
    const [activeAccordion, setActiveAccordion] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        if (activeAccordion === index) {
            setActiveAccordion(null);
        } else {
            setActiveAccordion(index);
        }
    };

    const accordionItems = [
        {
            title: 'What services do you offer?',
            content: 'We offer a wide range of services, including haircuts, styling, coloring, highlights, extensions, manicures, pedicures, facials, waxing, and more.',
        },
        {
            title: 'How can I book an appointment?',
            content: 'Booking an appointment is easy! You can either call our salon directly or use our online booking system on the "Book Appointment" page.',
        },
        {
            title: 'Are your products and treatments safe?',
            content: 'Yes, all our products and treatments are carefully selected and tested to ensure safety and quality.'
        },
        {
            title: 'Can I cancel or reschedule my appointment?',
            content: 'Yes, you can cancel or reschedule your appointment by contacting us at least 24 hours in advance.'
        },
        {
            title: 'Do you offer group bookings?',
            content: 'Yes, we offer group bookings for special occasions. Contact us for more information.'
        },
        {
            title: 'What should I wear to my appointment?',
            content: 'Wear comfortable clothing. For spa treatments, you will be provided with a robe and slippers.'
        },
        {
            title: 'How early should I arrive for my appointment',
            content: 'We recommend arriving 10-15 minutes before your scheduled appointment time.'
        },
        {
            title: 'Do you offer gift certificates',
            conttent: 'Yes, we offer gift certificates that can be purchased online or at our salon'
        },
        {
            title: 'Can I bring my child to the salon?',
            content: 'For the comfort and relaxation of all clients, we request that you make childcare arrangements.'
        },
        {
            title: 'What payment methods do you accept?',
            content: 'We accept credit/debit cards and cash for payment. Contactless payment options are available.'
        },

    ];

    return (
        <>
            <div className="container py-4">
                <h1 className="text-lg font-semibold mb-4">Frequently Asked Questions</h1>
                {accordionItems.map((item, index) => (
                    <div key={index} className={`border rounded-lg p-4 mb-2 ${activeAccordion === index ? 'bg-gray-100' : 'bg-white'}`} >
                        <div
                            className="flex items-center justify-between cursor-pointer"
                            onClick={() => toggleAccordion(index)}
                        >
                            <h3 className="text-lg font-medium">{item.title}</h3>
                            <svg
                                className={`w-6 h-6 transform transition-transform duration-300 ${activeAccordion === index ? 'rotate-180' : ''
                                    }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                        {activeAccordion === index && <p className="mt-2 text-gray-600">{item.content}</p>}
                    </div>
                ))}
            </div>


        </>
    );
};

export default FaqPage;
