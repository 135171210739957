import { useRef, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../images/massage1 (1).png';
import img2 from '../../images/facial.png';
import img3 from '../../images/hair-spa.png';
import img4 from '../../images/make-up.png';
import img5 from '../../images/haircut.png';
import img6 from '../../images/ayurveda.png';
import img7 from '../../images/hair colour.png';
import img8 from '../../images/facial-mask.png';
import img9 from '../../images/nail.png';
import img10 from '../../images/Beard.png';
import img11 from '../../images/eyebrow.png';
import img12 from '../../images/mehendi.png';
import img13 from '../../images/waxing1 (1).png';


import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTimeoutFn } from "react-use";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { Dialog, Tab, Transition } from "@headlessui/react";

interface MenuItem {
  id: number;
  name: string;
}

const images = [
  { src: img5, alt: 'Haircut', href: '/listing?query=HairCut' },
  { src: img4, alt: 'Makeup', href: '/listing?query=Makeup' },
  { src: img1, alt: 'Massage', href: '/listing?query=massage' },
  { src: img2, alt: 'Facial', href: '/listing?query=facial' },
  { src: img3, alt: 'Hair Spa', href: '/listing?query=hairSpa' },
  { src: img6, alt: 'Ayurveda', href: '/listing?query=Ayurveda' },
  { src: img7, alt: 'Haircolor', href: '/listing?query=HairColor' },
  { src: img8, alt: 'Masks', href: '/listing?query=Masks' },
  { src: img9, alt: 'Nail', href: '/listing?query=Nail' },
  { src: img10, alt: 'Shaving', href: '/listing?query=Shaving' },
  { src: img11, alt: 'Threading', href: '/listing?query=threading' },
  { src: img12, alt: 'Mehendi', href: '/listing?query=mehendi' },
  { src: img13, alt: 'Waxing', href: '/listing?query=Waxing' },

];
const menuItems: MenuItem[] = [
  { id: 1, name: "SPA" },
  { id: 2, name: "SALON" },
  { id: 3, name: "WELLNESS" },
];
const KeywordListingMapFilters = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
  <div className={`absolute  top-0 flex left-0 right-0 z-10 mb-12 lg:mb-2 w-full flex-shrink-0 ${isMobile ? "overflow-x-auto overflow-y-hidden" : "overflow-hidden"}`}>
    <div className="flex space-x-4 pt-4 md:space-x-5 items-center w-full mt-20">


      <div>
          <button onClick={scrollLeft} className='p-4 drop-shadow-md'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" className="block fill-none h-6 w-6 stroke-current stroke-5.33333 overflow-visible"   style={{
        stroke: 'white', // Change the color to white
        fontWeight: 'bold', // Set the font weight
      }}>
              <path fill="none" d="M20 28 8.7 16.7a1 1 0 0 1 0-1.4L20 4"></path>
            </svg>
          </button>
      </div>
    
      <div ref={scrollRef} className="flex w-[100%] space-x-4 pt-4 md:space-x-14 items-center flex-nowrap overflow-x-scroll scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200 w-full">
        {images.map((image, index) => (
      <div key={index} className="items-center block">
        <a href={image.href} title={image.alt}>
          <p
            className="font-semibold text-white"
            style={{ fontSize: "16px", whiteSpace: "nowrap" }}
          >
            {image.alt}
          </p>
        </a>
      </div>
        ))}
      </div>

      <div>
          <button onClick={scrollRight} className="p-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" className="block fill-none h-6 w-6 stroke-current stroke-5.33333 overflow-visible"   style={{
        stroke: 'white', // Change the color to white
        fontWeight: 'bold', // Set the font weight
      }}>
              <path fill="none" d="m12 4 11.3 11.3a1 1 0 0 1 0 1.4L12 28"></path>
            </svg>
          </button>
      </div>

    </div>
  </div>


  );
};

export default KeywordListingMapFilters;
