import React, { FC, useState, useEffect } from "react";
import axios from "axios";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  business_id?: number | string;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  isLiked = false,
  business_id
}) => {
  const [likedState, setLikedState] = useState(isLiked);

  const readCookie = (cookieName: string): string | null => {
    const nameEQ = cookieName + "=";
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const userId = readCookie("userId");

  useEffect(() => {
    if(userId !== null && business_id !== undefined) {
      axios.post("/api/customer/check/fav", {
        user_id: userId,  
        business_id: business_id
      })
      .then((response) => {
        setLikedState(response.data.fav);

      })
      .catch((error) => {
        console.log(error)
      });
    }
  })


  const like = () => {
    if(userId == null) {
      window.location.href = "/login";
    }
    else {

      if(likedState == true) {
        setLikedState(false)
      }
      else {
        setLikedState(true)
      }

      axios.post("/api/customer/toggle/fav", {
        user_id: userId,  
        business_id: business_id
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error)
      });
    }
  }





  return (
    <div
      className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
        likedState ? "nc-BtnLikeIcon--liked" : ""
      }  ${colorClass} ${className}`}
      data-nc-id="BtnLikeIcon"
      title="Save"
      onClick={() => like()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill={likedState ? "currentColor" : "none"}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        />
      </svg>
    </div>
  );
};

export default BtnLikeIcon;
