import React, { useState } from "react";
import axios from 'axios';

interface AddReviewProps {
    businessId: number | undefined;
    closeReview: () => void;
    orderId: string | undefined;
}

const AddReview: React.FC<AddReviewProps> = ({ businessId, closeReview, orderId }) => {
    const [cleanliness, setCleanliness] = useState<number>(0);
    const [value, setValue] = useState<number>(0);
    const [services, setServices] = useState<number>(0);
    const [hygiene, setHygiene] = useState<number>(0);
    const [staff, setStaff] = useState<number>(0);
    const [overallRating, setOverallRating] = useState<number>(0);

    const [ratingText, setRatingText] = useState<string>("");

    const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);

    const handleStarClick = (aspect: string, starCount: number) => {
        switch (aspect) {
            case 'cleanliness':
                setCleanliness(starCount);
                break;
            case 'value':
                setValue(starCount);
                break;
            case 'services':
                setServices(starCount);
                break;
            case 'hygiene':
                setHygiene(starCount);
                break;
            case 'staff':
                setStaff(starCount);
                break;
            default:
                break;
        }
    };

    const handleRatingChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRatingText(event.target.value);
    };

    const handleSubmit = () => {
        const userId = document.cookie.replace(
            /(?:(?:^|.*;\s*)userId\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
        );
        const data = {
            userId,
            businessId,
            orderId,
            cleanliness,
            value,
            services,
            hygiene,
            staff,
            overallRating,
            ratingDescription: ratingText
        };

        axios.post('/api/business/add/rating', data)
            .then(response => {

                setShowSuccessPopup(true); // Show success pop-up
            })
            .catch(error => {
                console.error("Error submitting review:", error);
            });
    };

    return (
        <>
            {showSuccessPopup ? (
                <div className="fixed top-20 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <p className="text-green-500 text-xl">Rating added successfully!</p>
                        <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={() => {setShowSuccessPopup(false); closeReview() }}>Close</button>
                    </div>
                </div>
            ) : (
                <>
                <div onClick={closeReview} className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50" style={{ zIndex: 50 }} />
                    <div className="bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 shadow-xl p-4 rounded-lg border overflow-y-auto max-h-[600px] mt-5 scrollbar" style={{ zIndex: 50 }}>

                        <div className="flex justify-between py-4">
                            <div className="font-bold text-lg"> Rate your experience </div>
                            <button
                                className="text-gray-600 hover:text-gray-800"
                                onClick={closeReview}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="mb-4 flex items-center justify-between">
                            <div className="text-xl mb-2">Cleanliness</div>
                            <div>
                            {[1, 2, 3, 4, 5].map((starCount) => (
                                <span
                                    key={starCount}
                                    className={`cursor-pointer text-3xl ${
                                        starCount <= cleanliness ? "text-yellow-500" : "text-gray-400"
                                    }`}
                                    onClick={() => handleStarClick('cleanliness', starCount)}
                                >
                                    ★
                                </span>
                            ))}
                            </div>
                        </div>
                        <hr className="mb-4" />

                        <div className="mb-4 flex items-center justify-between">
                            <div className="text-xl mb-2">Value</div>
                            <div>
                            {[1, 2, 3, 4, 5].map((starCount) => (
                                <span
                                    key={starCount}
                                    className={`cursor-pointer text-3xl ${
                                        starCount <= value ? "text-yellow-500" : "text-gray-400"
                                    }`}
                                    onClick={() => handleStarClick('value', starCount)}
                                >
                                    ★
                                </span>
                            ))}
                            </div>
                        </div>
                        <hr className="mb-4" />

                        <div className="mb-4 flex items-center justify-between">
                            <div className="text-xl mb-2">Services</div>
                            <div>
                            {[1, 2, 3, 4, 5].map((starCount) => (
                                <span
                                    key={starCount}
                                    className={`cursor-pointer text-3xl ${
                                        starCount <= services ? "text-yellow-500" : "text-gray-400"
                                    }`}
                                    onClick={() => handleStarClick('services', starCount)}
                                >
                                    ★
                                </span>
                            ))}
                            </div>
                        </div>
                        <hr className="mb-4" />

                        <div className="mb-4 flex items-center justify-between">
                            <div className="text-xl mb-2">Hygiene</div>
                            <div>
                            {[1, 2, 3, 4, 5].map((starCount) => (
                                <span
                                    key={starCount}
                                    className={`cursor-pointer text-3xl ${
                                        starCount <= hygiene ? "text-yellow-500" : "text-gray-400"
                                    }`}
                                    onClick={() => handleStarClick('hygiene', starCount)}
                                >
                                    ★
                                </span>
                            ))}
                            </div>
                        </div>

                        <hr className="mb-4" />

                        <div className="mb-4 flex items-center justify-between">
                            <div className="text-xl mb-2">Staff</div>
                            <div>
                            {[1, 2, 3, 4, 5].map((starCount) => (
                                <span
                                    key={starCount}
                                    className={`cursor-pointer text-3xl ${
                                        starCount <= staff ? "text-yellow-500" : "text-gray-400"
                                    }`}
                                    onClick={() => handleStarClick('staff', starCount)}
                                >
                                    ★
                                </span>
                            ))}
                            </div>
                        </div>

                        <hr className="mb-4" />

                        <div className="mt-4">
                            <div className="flex items-center justify-between mt-4">
                                <div className="text-xl mr-4">Overall Rating:</div>
                                <div>
                                {[1, 2, 3, 4, 5].map((starCount) => (
                                    <span
                                        key={starCount}
                                        className={`cursor-pointer text-3xl ${
                                            starCount <= overallRating ? "text-red-400" : "text-gray-400"
                                        }`}
                                        onClick={() => setOverallRating(starCount)}
                                    >
                                        ★
                                    </span>
                                ))}
                                </div>
                            </div>
                            <label htmlFor="ratingText" className="mb-2 block">Your Review</label>
                            <textarea
                                id="ratingText"
                                className="w-full border rounded p-2"
                                placeholder="Add your rating (max 100 characters)"
                                maxLength={100}
                                value={ratingText}
                                onChange={handleRatingChange}
                            ></textarea>
                        </div>

                        <div className="flex justify-between mt-4">
                            <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-300" onClick={handleSubmit}>Submit Review</button>
                        </div>
                        </div>
                </>
            )}
        </>
    );
};

export default AddReview;
