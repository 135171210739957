import rightImg from "images/about us banner.jpg";
import React, { FC } from "react";
// import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
  <>
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
          <title>Feelaxo - About Us</title>
          <meta name="description" content="Discover Feelaxo.com, your innovative online destination for beauty and wellness services. Revolutionizing how users and professionals in the beauty industry connect." />
          <meta name="keywords" content="Feelaxo, beauty services, wellness services, beauty industry, online beauty services, beauty professionals" />
        </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading=" About Feelaxo"
          btnText=""
          subHeading="At Feelaxo.com, we are on a mission to revolutionize the way you experience 
          beauty and wellness services. We take pride in being an innovative online destination that 
          brings together users and professionals in the beauty industry, creating a seamless and hassle-free
             experience for everyone involved."
        />

        {/* <SectionFounder /> */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionStatistic />

        <SectionSubscribe2 />
      </div>
    </div>
  </>
  );
};

export default PageAbout;
