import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export interface BadgeProps {
  className?: string;
  name: ReactNode;
  href?: string;
  color?:string;
}

const Badge: FC<BadgeProps> = ({
  className = "relative",
  name,
  href,
  color,
}) => {

  const CLASSES =
    "nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs " +
    className;

  return !!href ? (
    <Link
      to={href || ""}
      className={`transition-colors duration-300 ${CLASSES}`}
      style={{ backgroundColor: 'white', color: 'black' }}
    >
      {name}
    </Link>
  ) : (
    <span className={`${CLASSES}`} style={{ backgroundColor: 'white', color: 'black' }}>
      {name}
    </span>
  );
};

export default Badge;
