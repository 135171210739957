import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios from "axios";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "ADDRESS",
    desc: "Nestcraft Design, D Mart Road, Kharghar, Navi Mumbai, Maharasthra, India",
  },
  {
    title: "EMAIL",
    desc: "pr@feelaxo.com",
  },
  {
    title: "PHONE [IN]",
    desc: "+91 9137573481",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  // State for form data
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: ""
  });

  // State for form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Handle form input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");

    try {
      const response = await axios.post("/api/customer/contact", formData);
      console.log("Form submitted successfully", response.data);
      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      console.error("Error submitting form", error);
      setErrorMessage("Failed to send message. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Feelaxo - Contact us</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
      <h1 className="mt-10 flex items-center text-sm uppercase font-semibold text-gray-500 justify-center">
        Contact Us
      </h1>

        <h2 className="mt-5 mb-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Feel free to contact us or just say hi! 
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                {/* <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" /> */}
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    name="fullName"
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                    name="message"
                    className="mt-1"
                    rows={6}
                    value={formData.message}
                    onChange={handleChange}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Send Message"}
                  </ButtonPrimary>
                </div>
                {errorMessage && <p className="text-red-600">{errorMessage}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
