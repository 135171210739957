import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/feelaxo-logo-web.png";
// import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  title?: string;
  alt?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
  title, 
  alt
}) => {
  return (
    <Link
      to="/"
      title="Feelaxo - Book top rated salons and spas"
      className={`ttnc-logo inline-block lg:w-40 md:w-36 text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight /> */}
      <LogoSvg title={title} alt={alt}/>

      
    </Link>
  );
};

export default Logo;
