import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useMemo } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
  business_type?: number;
  business_id?: number | string;
  opening?: string;
  business_name: string;
  business_address: string;
  business_city: string;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
  business_type,
  business_id,
  opening,
  business_name,
  business_address,
  business_city
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
            title={`Slide ${i + 1}`}
            aria-label={`Go to slide ${i + 1}`}
          />
        ))}
      </div>
    );
  };

  const getAltTitleTexts = () => {
    // Define keyword-rich texts for each type
    if (business_type === 1) {
      return [
        "Relaxing spa services near me",
        "Luxury spa experience nearby",
        "Affordable spa treatments near me",
        "Professional massage and spa services",
        "Top-rated spa centers in town",
      ];
    } else if (business_type === 2) {
      return [
        "Best salons near me for a fresh look",
        "Professional haircuts and styling nearby",
        "Top salons in the city for beauty services",
        "Luxury salon experience close to you",
        "Highly-rated salons near me for hair and nails",
      ];
    } else if (business_type === 3) {
      return [
        "Top wellness centers near me",
        "Holistic wellness and relaxation services",
        "Mind and body wellness programs nearby",
        "Best wellness spas near me",
        "Tranquil wellness retreats close to you",
      ];
    } else {
      return Array(galleryImgs.length).fill("Business image");
    }
  };

  const altTitleTexts = getAltTitleTexts();

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <div className="absolute flex w-full p-4 pt-6 z-50 h-10 items-center justify-between">
          <div className="flex text-sm">
            {business_type === 1 && <Badge name="spa" />}
            {business_type === 2 && <Badge name="salon" />}
            {business_type === 3 && <Badge name="Wellness" />}
          </div>
          {opening && <div></div>}
          <div><BtnLikeIcon business_id={business_id} /></div>
        </div>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {galleryImgs.map((item, index) => {
              // Check if it's the last image in the array
              const isLastImage = index === galleryImgs.length - 1;
              const altText = isLastImage
                ? `${business_name} - ${business_address}`
                : `${altTitleTexts[index % altTitleTexts.length]} - ${business_name}, ${business_city}`;
  
              return (
                <li key={index} className="glide__slide relative">
                  <a
                    target="_blank"
                    href={href}
                    className={`block ${ratioClass}`}
                    title={altText}
                    aria-label={altText}
                  >
                    <NcImage
                      src={item}
                      alt={altText}
                      title={altText}
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        {/* DOTS */}
        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
        {renderDots()}
        {/* NAV */}
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };
  

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
