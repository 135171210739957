import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const SeoUrl = () => {
    const [type, setType] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);
    const [businessName, setBusinessName] = useState<string | null>(null);
    const [id, setId] = useState<string | null>(null);

    const navigate = useNavigate();
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setType(urlParams.get('type'));
        setCity(urlParams.get('city'));
        setBusinessName(urlParams.get('business_name'));
        setId(urlParams.get('id'));
    }, []);

    useEffect(() => {
        if (type !== null && city !== null && businessName !== null && id !== null) {
            const url = `/venue/${type}/${city}/${businessName}?id=${id}`;
            navigate(url);
        }
    }, [type, city, businessName, id, navigate]);

    return (
        <>
            We are here
            id: {id}
            city: {city}
            business name: {businessName}
            type: {type}
        </>
    );
}

export default SeoUrl;
