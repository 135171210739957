import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";


export const NAVIGATION_DEMO: NavItemType[] = [
 
  
   {
     id: ncNanoId(),
     href: "/listing?query=&type=1",
     name: "Spa",
     title: "Top rated spa near you"
   }, 
   {
    id: ncNanoId(),
    href: "/listing?query=&type=2",
    name: "Salon",
    title: "Top rated salons near you"
  },
  {
    id: ncNanoId(),
    href: "/listing?query=&type=3",
    name: "Wellness",
    title: "top rated wellness centers near you"
  },
];
