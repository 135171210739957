import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import VerifyOtp from "containers/verifyOtp/VerifyOtp";

// Import your signup images
import signupImage1 from "../PageLogin/feelaxo-8.jpg";
import signupImage2 from "../PageLogin/feelaxo-11.jpg";
import signupImage3 from "../PageLogin/feelaxo-3.jpg";

const images = [signupImage1, signupImage2, signupImage3];

const PageSignUp: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [success, setSuccess] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);
  const [gender, setGender] = useState("other");

  const randomImage = useMemo(() => {
    return images[Math.floor(Math.random() * images.length)];
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setPasswordError(false);
      setEmailError("");
      setPhoneError("");

      try {
        const response = await axios.post("/api/customer/register", {
          name,
          email,
          phoneNumber,
          password,
          confirmPassword,
          gender,
        });

        const { message, userId } = response.data;

        if (message === "001") setPasswordError(true);
        if (message === "002") setEmailError("Please enter a correct email");
        if (message === "005") setEmailError("Email already exists");
        if (message === "003") setPhoneError("Please enter a correct phone number");
        if (message === "004") setPhoneError("Phone number already exists");
        if (message === "200") setUserId(userId);
      } catch (error) {
        console.error("Error signing up data: ", error);
      }
    },
    [name, email, phoneNumber, password, confirmPassword, gender]
  );

  return (
    <>
      <Helmet>
        <title>Feelaxo - Create an account</title>
        <meta name="description" content="Create your Feelaxo account to access beauty and wellness services. Sign up today to start exploring a world of beauty and wellness." />
        <meta name="keywords" content="Feelaxo signup, beauty services signup, wellness services signup, user signup, customer signup" />
      </Helmet>
      <div className="nc-PageSignUp" data-nc-id="PageSignUp">
        {success && (
          <div className="fixed inset-0 flex flex-col justify-center items-center bg-white shadow-2xl mx-auto rounded-md mt-20" style={{ zIndex: "999" }}>
            <h1 className="text-2xl text-center font-bold">Account created successfully!</h1>
            <button className="w-50 rounded-md text-white p-4 mx-auto bg-purple-400 mt-4" onClick={() => window.location.href = "/login"}>
              Login Here
            </button>
          </div>
        )}
        <div className="flex flex-col md:flex-row md:h-screen">
          <div className="hidden md:block md:w-1/2 bg-gray-100">
            <img src={randomImage} alt="Sign Up" className="w-full h-full object-cover" />
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center p-4 md:p-10">
            <div className="max-w-md w-full space-y-6">
              <h1 className="font-semibold text-3xl text-center mb-10">Sign Up!</h1>
              <form className="grid grid-cols-1 gap-3" onSubmit={handleSubmit}>
                <label className="block w-full">
                  <span className="text-neutral-800 dark:text-neutral-200">Name</span>
                  <Input
                    type="text"
                    placeholder="John Doe"
                    className="mt-1 w-full"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <label className="block w-full">
                  <span className="text-neutral-800 dark:text-neutral-200">Email address</span>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className={`mt-1 w-full ${emailError ? "font-red-500 border-red-500" : ""}`}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className={`text-red-500 mt-1 ${emailError ? "visible" : "hidden"}`}>{emailError}</p>
                </label>
                <label className="block w-full">
                  <span className="text-neutral-800 dark:text-neutral-200">Phone number</span>
                  <Input
                    type="tel"
                    placeholder="1234567890"
                    className={`mt-1 w-full ${phoneError ? "font-red-500 border-red-500" : ""}`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <p className={`text-red-500 mt-1 ${phoneError ? "visible" : "hidden"}`}>{phoneError}</p>
                </label>
                <label className="block w-full">
                  <span className="text-neutral-800 dark:text-neutral-200">Gender</span>
                  <select
                    className="mt-1 w-full border border-neutral-300 dark:border-neutral-700 rounded-2xl h-11 focus:border-primary-400 focus:ring-primary-400"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    required
                  >
                    <option value="">Please Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </label>
                <label className="block w-full">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Password</span>
                  <Input
                    type="password"
                    className={`mt-1 w-full ${passwordError ? "font-red-500 border-red-500" : ""}`}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <p className={`text-red-500 mt-1 ${passwordError ? "visible" : "hidden"}`}>Passwords do not match</p>
                </label>
                <label className="block w-full">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Confirm Password</span>
                  <Input
                    type="password"
                    className={`mt-1 w-full ${passwordError ? "font-red-500 border-red-500" : ""}`}
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <p className={`text-red-500 mt-1 ${passwordError ? "visible" : "hidden"}`}>Passwords do not match</p>
                </label>
                <p className="text-sm text-center text-gray-500">By clicking on continue you are accepting our terms and conditions & privacy policy</p>
                <ButtonPrimary className="mx-auto w-full" type="submit">Continue</ButtonPrimary>
              </form>
            </div>
          </div>
        </div>
      </div>
      {userId != null && <VerifyOtp user_id={userId} />}
    </>
  );
};

export default PageSignUp;
