import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";
import AccountPage from "containers/AccountPage/AccountPage";
import FooterNav from "components/FooterNav";
import ListingStayMapPage from "containers/SearchPage/ListingStayMapPage";
import PrivacyPolicy from "containers/privacyPolicy/PrivacyPolicy";
import TermsCondition from "containers/TermsCondition/TermsCondition";
import ContactUsPage from "containers/ContactUsPage/ContactUsPage";
import FaqPage from "containers/FaqPage/FaqPage";
import listingStayDetail from 'containers/VenueDetails/BusinessDetails';
import order from 'containers/AccountPage/AccountOrders';
import ForBusiness from "containers/ForBusiness/ForBusiness";
import PageLogout from "containers/PageLogout/PageLogout";
import ForgotPass from "containers/ForgotPass/ForgotPass";
import OrderBooking from "containers/Booking/OrderBooking";
import Wishlist from "containers/AccountPage/Wishlist";
import SeoUrl from "components/SeoUrl/SeoUrl";
import DemoForm from "containers/DemoForm/DemoForm";
import PersonalWebsite from "containers/VenueDetails/PersonalWebsite";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home", exact: true, component: PageHome },
  { path: "/privacyPolicy", exact: true, component: PrivacyPolicy },
  { path: "/termsCondition", exact: true, component: TermsCondition },
  { path: "/FAQ", exact: true, component: FaqPage },
  { path: "/contact", exact: true, component: ContactUsPage },
  { path: "/about", component: PageAbout },
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/dashboard", component: AccountPage },
  { path: "/listing", component: ListingStayMapPage },
  { path: "/venue/:type/:city/:name", component: listingStayDetail },
  { path: "/orders", component: order },
  { path: "/businesses", component: ForBusiness },
  { path: "/logout", component: PageLogout },
  { path: "/forgot-pass", component: ForgotPass },
  { path: "/booking", component: OrderBooking },
  { path: "/wishlist", component: Wishlist },
  { path: "/seo", component: SeoUrl },
  { path: "/contact-for-demo", component: DemoForm },
  { path: "/venue-site", component: PersonalWebsite }
];

const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width;

  const shouldHideHeaderAndFooter = (path: string) => {
    // Add conditions for routes where you want to hide the header and footer
    return path === "/venue-site";
  };

  const shouldHideHeader = (path: string) => {
    return (
      WIN_WIDTH < 768 &&
      (path === "/venue/:type/:city/:name" ||
        path === "/login" ||
        path === "/signup" ||
        path === "/" ||
        path === "/home" ||
        path === "/#" ||
        path === "/venue-site")
    );
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          const hideHeaderAndFooter = shouldHideHeaderAndFooter(path);
          return (
            <Route
              key={path}
              path={path}
              element={
                <React.Fragment>
                  {!hideHeaderAndFooter && !shouldHideHeader(path) && <SiteHeader />}
                  <Component />
                  {!hideHeaderAndFooter && (
                    <>
                      {WIN_WIDTH < 768 && <FooterNav />}
                      <Footer />
                    </>
                  )}
                </React.Fragment>
              }
            />
          );
        })}
        {/* 404 Page */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
