import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import img from 'images/salon.png';
import img1 from 'images/spa icon.png';
import img2 from 'images/Tattoo icon.png';
import img3 from 'images/wellness icon.png';

interface SolutionItem {
  id: number;
  name: string;
  description: string;
  business_name: string;
  imageSrc: string;
  active?: boolean;
}

const DropdownTravelers: React.FC = () => {
  const [solutions, setSolutions] = useState<SolutionItem[]>([]);

  useEffect(() => {
    axios.get('/api/services')
      .then(response => {
        const fetchedSolutions: SolutionItem[] = response.data.map((service: any) => ({
          id: service.id,
          name: service.business_name,
          description: getDescription(service.business_name),
          business_name: service.business_name,
          imageSrc: getImageSrc(service.business_name),
        }));
        // Reorder solutions array to place "Salon" at the top
        const sortedSolutions = sortSolutions(fetchedSolutions);
        setSolutions(sortedSolutions);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getImageSrc = (business_name: string): string => {
    switch (business_name) {
      case "Salon":
        return img;
      case "Spa":
        return img1;
      case "Tattoo":
        return img2;
      case "Wellness Center":
        return img3;
      default:
        return '';
    }
  };

  const descriptions: { [key: string]: string } = {
    Salon: "Discover best deals on salon services near you ",
    Spa: "Book the finest and top-rated SPA services online",
    Tattoo: "Unleash creativity in tattoo studios with our business solutions.",
    "Wellness Center": "Revitalize Your Well-being: Book the top rated Wellness Center"
  };

  const getDescription = (business_name: string): string => {
    return descriptions[business_name] || "";
  };

  const sortSolutions = (solutions: SolutionItem[]): SolutionItem[] => {
   
    const salonIndex = solutions.findIndex(item => item.business_name === "Salon");
    if (salonIndex !== -1) {
      const sortedSolutions = [solutions[salonIndex], ...solutions.slice(0, salonIndex), ...solutions.slice(salonIndex + 1)];
      return sortedSolutions;
    }
    return solutions;
  };

  return (
    <div className="DropdownTravelers">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`${open ? "" : "text-opacity-90"}
                group py-2 rounded-md text-sm sm:text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <div className={` inline-flex items-center py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover-bg-neutral-800 dark:hover:text-neutral-200 `} role="button">
                <span>Listing</span>
                <ChevronDownIcon
                  className={`${open ? "-rotate-180" : "text-opacity-70 "}
                  ml-2 h-5 w-5 text-neutral-700 group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                  aria-hidden="true"
                />
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute drop-shadow-lg bg-white rounded-2xl z-50 w-screen max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                <div className="overflow-hidden bg-white z-50 rounded-2xl">
                  <div className="relative  rounded-2xl z-50 grid grid-cols-1 gap-8 bg-white dark:bg-neutral-800 p-7 ">
                    {solutions.map((item, index) => (
                      <>
                        {
                          item.id !== 11 && (
                            <a
                              key={index}
                              href={`/listing?query=&type=${item.id}`} // Update href with business_name
                              onClick={() => close()}
                              className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${item.active
                                ? "bg-neutral-100 dark:bg-neutral-700"
                                : ""
                              }`}
                            >
                              <div className="flex items-center justify-center flex-shrink-0 w-10 h-10  rounded-md text-primary-500 sm:h-12 sm:w-12">
                                <img src={item.imageSrc} alt={item.name} className="w-full h-full object-cover rounded-md" />
                              </div>
                              <div className="ml-4 space-y-0.5">
                                <p className="text-sm font-medium ">{item.name}</p>
                                <p className="text-xs text-neutral-500 dark:text-neutral-300">
                                  {item.description}
                                </p>
                              </div>
                            </a>
                          ) 
                        }
                      </>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

export default DropdownTravelers;
