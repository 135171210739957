import React, { useEffect, useState } from 'react';
import { FrontendData } from '../../types/types';
import './style.css';


interface SelectCityProps {
  isVisible: boolean;
  onClose: () => void;
  onCitySelect: (city: FrontendData) => void;
  updateParentVisibility: (isVisible: boolean) => void;
}

function SelectCity({ isVisible, onClose, onCitySelect, updateParentVisibility }: SelectCityProps) {
  const [cities, setCities] = useState<FrontendData[]>([]);
  const [errorVisible, setErrorVisible] = useState(false);

  const setCookie = (name: string, value: string, expirationDate: Date) => {
    let cookieString = `${name}=${value};expires=${expirationDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/cities/get/topcities');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCities(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleCityClick = async (city: FrontendData) => {
    const frontendData = city;
    console.log(frontendData)
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 3);
    setCookie('frontendData', JSON.stringify(frontendData), expirationDate);
    window.location.reload();
    updateParentVisibility(false);
  };

  useEffect(() => {
    const frontendDataCookie = document.cookie.match(/frontendData=([^;]*)/);

    if (frontendDataCookie) {
      updateParentVisibility(false);
    }
  }, [updateParentVisibility]);

  const handleClose = () => {
    const frontendDataCookie = document.cookie.match(/frontendData=([^;]*)/);
    

    if (frontendDataCookie) {
      onClose();
    }
    else {
      handleCityClick(cities[0])
    }
  }

  return (
    <div className={`popup-wrap z-50 ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="searchWrap">
        <div className="SearchLocation pb-4 relative">
          <div onClick={() => handleClose()} className="absolute top-5 text-4xl right-5 pb cursor-pointer hover:text-red-500">×</div>
          <div className="SearchLocationHeading" id="feelaxo">
            <img className="feelaxo-logo" src="https://nestcraft.sirv.com/Feelaxo/feelaxo-logo.png" alt="" />
          </div>
          <div className="SearchLocationPara">
            Book top-rated salons and spas near you
          </div>
          <div className="SearchLocationPara" id="bold">
            Select from top cities
          </div>
          <div className="city_button_wrap">
            {cities.map((city) => (
              <button className="cityButton" key={city.city_id} onClick={() => handleCityClick(city)}>
                {city.city_name}
              </button>
            ))}
            <button className="cityButton" id="explore" onClick={() => handleCityClick(cities[0])}>
            Explore
          </button>
          </div>
          <a href="https://pos.feelaxo.com" className="p-4 mb-8 cursor-pointer underline hover:font-bold" style={{paddingBottom: "10px"}}>List your business on feelaxo</a>
        </div>
      </div>
    </div>
  );
}

export default SelectCity;
