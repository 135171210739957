import React from 'react'
import { Helmet } from "react-helmet";


export default function TermsCondition() {
  return (
    <>
      <div className={`nc-TermsCondition overflow-hidden relative`} data-nc-id="TermsCondition">
        <Helmet>
          <title>Terms Condition || Feelaxo </title>
        </Helmet>

        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
          <h1 className='font-semibold text-4xl'>Terms And Conditions</h1>
          <p className='text' >Terms and conditions <b>Acceptance of terms and conditions</b> </p>
          <p className='font-poppins font-helvetica font-sans font-normal text-base'>
            General: by using http://www.Feelaxo.com (the <b> “website”</b> or <b> “Feelaxo.com”</b> in this document),
            you, the customer (<b>“you”, “your”</b>, or the “<b>user</b>” in this document) unconditionally agree to the terms and conditions that we at, <b>Feelaxo.com</b>  With our registered office at #804, D Mart Rd, Sector 15, Kharghar, Navi Mumbai, Maharashtra 410210 and having (“<b>Feelaxo.com</b>”, “<b>we</b>” or “<b>us</b>” or “<b>our</b>” in this document) have provided herein for use of Feelaxo’s website or mobile application owned and maintained by us. This website or mobile application and any individual websites or merchant-specific, city-specific, or state-specific sites now or hereinafter contained within or otherwise available through external hyperlinks (“<b>minisites</b>”) with our website or mobile application are private property. All interactions on this website or mobile application must comply with these <b>terms of use</b>. If you do not wish to agree to the outlined terms and conditions (the “terms of use” in this document) please refrain from using this website or mobile application. The term website shall include any or all minisites, wherever mentioned.
          </p>
          <div className=''>
            <p className='font-poppins font-helvetica font-sans font-normal text-base'> for the purpose of these terms of use, wherever the context so requires “you” or “user” shall mean
              any natural or legal person who has agreed to become a buyer on the website or mobile application by
              providing registration data while registering on the website or mobile application as registered user
              using the computer systems or mobile device. <b>Feelaxo.com</b> allows the user to surf the website or making
              purchases without registering on the website. The term “we”, “us”, “our” shall mean <b>Feelaxo.com</b>. The
              terms Feelaxo.com and <b>Feelaxo.com</b> have been used interchangeably in the document.
            </p><br /><br />
            <p className='font-poppins font-helvetica font-sans font-normal text-base'>
              Caution: Please read this agreement carefully.  By browsing, accessing or using this website or mobile
              application or by using any facilities or services made available through or on it, you are agreeing to the
              terms of use that appear below (all of which are called the “<b>Agreement</b>”). This agreement is made between you
              and us. Use of the services and the website or mobile application and any purchase made thereto are each
              subjects to the terms of use set out in this agreement.
              Amendments and changes: we reserve the right to amend these terms and conditions at any time.  All amendments to these terms and conditions will be posted on-line. Such changes may include, among other things, the adding of certain fees or charges. We suggest to you, therefore, that you re-read this important notice containing our terms of use and privacy policy from time to time so that you stay informed as to any such changes. If we make changes to our terms of use and privacy policy and you continue to use our website or mobile application, you are implicitly agreeing to the amended terms of use and privacy policy. Unless specified otherwise, any such deletions or modifications shall be effective immediately upon <b>Feelaxo.com’s</b>
              posting thereof. Continued use of any of the services provided by<b> Feelaxo.com</b> via the website or mobile application (or via other electronic or other communication from <b>Feelaxo.com</b>) including the information services, content and transaction capabilities on the website or mobile application, including the ability to make a purchase (all of which are called the “<b>services</b>” in this document) will be deemed to constitute acceptance of the new terms and conditions. Feelaxo.com may immediately terminate these terms or any services with respect to you, or generally, cease offering or deny access to the services or any portion thereof, at any time for any reason.
            </p><br />
            <div>
              <h4><b>General & Payments</b></h4>
              <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                <b className='font-poppins font-helvetica font-sans font-normal text-base'>User accounts</b>: in order to use most aspects of the services, you must register for and maintain
                an active personal user Services account (“Account”). You must be at least 18 years of age, or the
                age of legal majority in your jurisdiction (if different than 18), to obtain an account. Account
                registration requires you to submit to <b>Feelaxo.com</b> certain personal information, such as your
                name, address, mobile phone number, as well as at least one valid payment method
                (either a credit card or accepted payment partner) at the time of making payment.
                You agree to maintain accurate, complete, and up-to-date information in your account.
                Your failure to maintain accurate, complete, and up-to-date Account information, including
                having an invalid or expired payment method on file, may result in your inability to access
                and use the services or <b>Feelaxo.com’s</b> termination of these terms with you. You are responsible
                for all activity that occurs under your account, and you agree to maintain the security and
                secrecy of your account username and password at all times. Unless otherwise permitted by <b>Feelaxo.com</b> in writing, you may only possess one account.<br />

                <b>Cancellation Policy</b> : 100% refund will be provided if the customer cancels the placed service 1 hour before the booking scheduled time. The refund amount will be credited to the customer’s account within 48 working hours.<br />
                <b>Territory</b>: the website or mobile application and the services and any purchase are directed solely at those who access the website from the republic of India. <b>Feelaxo.com</b> makes no representation that service (or any goods or services) are available or otherwise suitable for use outside the republic of India.  If you choose to access the website or mobile application (or use the service or make a purchase) from locations outside the republic of India, you do so on your own initiative and are responsible for the consequences
                thereof.<br /><br />

                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  <b>Website and/or mobile application: Feelaxo.com</b> provides an interactive online service owned and operated by
                  <b>Feelaxo.com</b> through the website on the world wide web of the internet (the <b>“web”</b> or <b>“internet”</b>) and its
                  mobile application, consisting of information services, content and transaction capabilities provided by
                  Feelaxo.com, its holding company and its associates, if any, with whom it has business relationships
                  (“business associates”) including but not limited to third parties that provide services in relation to
                  creation, production or distribution of content for the website or mobile application (“third-party
                  content providers”), third parties that provide advertising services to <b>Feelaxo.com (“third-party
                    advertisers”)</b> and third parties that perform function on behalf of  Feelaxo.com like sending out
                  and distributing our administrative and promotional emails and text messages (“third-party service providers”).
                  <b>Right to transfer</b>: the right to use this website or mobile application is personal to the user and is not transferable to any other person or entity.  The user shall be responsible for protecting the confidentiality of the user’s password(s) if any. User understands and acknowledges that, although the internet is often a secure environment, sometimes there are interruptions in service or events that are beyond the control of <b>Feelaxo.com</b>, and <b>Feelaxo.com</b> shall not be responsible for any data lost while transmitting information on the internet.
                </p>

                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  <b>Not on the uninterrupted basis</b>: while it is <b>Feelaxo.com’s objective</b> to make the website or mobile application
                  accessible at all times, the website or mobile application may be unavailable from time to time for any
                  reason including, without limitation, routine maintenance. You understand and acknowledge that due to
                  circumstances both within and outside of the<b> control of Feelaxo.com</b>, access to the website or mobile
                  application may be interrupted, suspended or terminated from time to time.<b> Feelaxo.com </b>reserves the right,
                  in its sole discretion, to terminate the access to any or all Feelaxo.com website(s) and the related
                  services or any portion thereof at any time, without notice. <b>Feelaxo.com</b> shall also have the right at
                  any time to change or discontinue any aspect or feature of the website or mobile application, including,
                  but not limited to, content, graphics, offers, settings, hours of availability and equipment needed for
                  access or use. Further, <b>Feelaxo.com</b> may discontinue disseminating any portion of information or category
                  of information, may change or eliminate any transmission method and may change transmission speeds or
                  other signal characteristics.
                </p><br />

                <h3><b className='font-medium text-lg font-semibold'>Equipment</b></h3><br />
                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  User’s responsibility: the services and use of the website or mobile application do not
                  include the provision of a computer or other necessary equipment to access the site or the
                  services. To use the site or services, you will require internet connectivity and appropriate
                  telecommunication links. The user shall be responsible for obtaining and maintaining all
                  telephone, computer hardware, software and other equipment needed for access to and use
                  of this website or mobile application and all charges related thereto.  <b>Feelaxo.com</b> shall
                  not be liable for any damages to the user’s equipment resulting from the use of this
                  website or mobile application.<br /><br />
                  <p><b>User conduct Restrictions:</b> the user undertakes without limitation, not to use or permit anyone else to
                    use the service or website or mobile application:
                  </p>
                </p><br />

                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  To upload, send or receive any information for which you have not obtained all necessary licenses and/or approvals (from us or third parties); or which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability or otherwise be contrary to the law of or infringe the rights of any third party in any country in
                  To upload, send or receive any material which is technically harmful (including computer viruses, logic bombs, trojan horses, worms, harmful components, corrupted data, malicious software, harmful data, or anything else which may interrupt, interfere with, corrupt or otherwise cause loss, damage, destruction or limitation to the functionality of any software or computer equipment);
                  To intercept or attempt to intercept any communications transmitted by way of a telecommunication system;<br />
                  <br />
                  – for a purpose other than which we have designed them or intended them to be used;– for any fraudulent purposes.
                  <br /><br />
                  – in any way which is calculated to incite hatred against any ethnic, religious or any other minority or is otherwise calculated to adversely affect any individual, group or entity.
                </p><br />
                <p className='font-poppins font-helvetica font-sans font-normal text-base'>To upload, send or receive any material, which is not civil or tasteful.
                  To upload, send or receive any material, including user content, which is unlawful, harmful, tortious, threatening, abusive, harassing, hateful, racist, homophobic, infringing, pornographic, violent, misleading, grossly offensive, of an indecent, obscene or menacing character, blasphemous or defamatory or of a libellous nature of any person or entity, in contempt of court or in breach of confidence, or which infringes the rights of another person or entity, including copyrights, trademarks, trade secrets, patents, rights of personality, publicity or privacy or any other third party rights;
                  To cause annoyance, inconvenience or needless anxiety.
                  <br /><br />
                  Forbidden uses: the following uses of the site and services are expressly prohibited and you undertake not to do (or to permit anyone else to do) any of the following:
                </p>

                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  Resell the services or site;<br />
                  Furnish false data including false names, addresses, and contact details and fraudulent use of credit/debit card numbers;
                  Attempt to circumvent our security or network including accessing data not intended for you, logging into a server or account you are not expressly authorized to access, or probe the security of other networks (such as running a port scan);
                  Access the services (or site) in such a way as to, or commit any act that would or does, impose an unreasonable or disproportionately large burden on our infrastructure or that otherwise interrupts or interferes with its functionality, efficiency or operation;<br />
                  Execute any form of network monitoring which will intercept data not intended for you;<br />
                  Send unsolicited mail messages, including the sending of “junk mail” or other advertising material to individuals who did not specifically request such material. You are explicitly prohibited from sending unsolicited bulk mail messages. This includes bulk mailing of commercial advertising, promotional, or informational announcements, and political or religious tracts. Such material may only be sent to those who have explicitly requested it. If a recipient asks to stop receiving an email of this nature, you may not send that person any further email;<br />
                  Create or forward “chain letters” or other “pyramid schemes” of any type, whether or not the recipient wishes to receive such mailings;
                  Send a malicious email, including flooding a user or site with very large or numerous emails;<br />
                  Enter into fraudulent interactions or transactions with us, a seller or a merchant (which shall include entering into interactions or transactions purportedly on behalf of a third party where you have no authority to bind that third party or you are pretending to be a third party);
                  Use the services or site (or any relevant functionality of either of them) in breach of this agreement;<br />
                  Use in an unauthorized manner, or forge, mail header information;<br />
                  Engage in any unlawful or criminal activity in connection with the use of the services and/or the site or any voucher; or<br />
                  Copy or use any user content for any commercial purposes.<br /><br />
                </p>

                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  Any conduct by a user that in <b>Feelaxo.com's </b>exclusive discretion is in breach of the terms of use or which restricts or inhibits any other user from using or enjoying this website or mobile application is strictly prohibited. The user shall not use this website or mobile application to advertise or perform any commercial, religious, political or non-commercial solicitation, including,
                  but not limited to, the solicitation of users of this website or mobile application to become users of other online or offline services directly or indirectly competitive or potentially competitive with <b>Feelaxo.com.</b><br />
                  <b>Feelaxo.com</b> reserves the right to prevent you from using the website (or mobile
                  application) and the service (or any part of thereof) and to prevent you from making any purchase if your conduct is found to be in question or contravention of such terms as mentioned above or in this agreement.
                </p><br />
                <h3><b className='font-medium text-lg font-semibold'>Promotional codess</b></h3><br />
                <p className='font-poppins font-helvetica font-sans font-normal text-base'><b>Feelaxo.com</b> may, in <b>Feelaxo.com’s</b> sole discretion, create promotional codes that may be
                  redeemed for account credit, or other features or benefits related to the services
                  and/or a third-party provider’s services, subject to any additional terms that
                  Feelaxo.com establishes on a per promotional code basis (“promo codes”). You agree
                  that promo codes: (i) must be used for the intended audience and purpose, and in a
                  lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made
                  available to the general public (whether posted to a public form or otherwise), unless
                  expressly permitted by <b>Feelaxo.com</b>; (iii) may be disabled by <b>Feelaxo.com</b> at any time
                  for any reason without liability to Feelaxo.com; (iv) may only be used pursuant to the specific terms that <b>Feelaxo.com</b> establishes for such promo code; (v) are not valid for cash; vi) may expire prior to your use, and vii) unless otherwise specified cannot be used more than once. <b>Feelaxo.com</b> reserves the right to withhold or deduct credits or other features or benefits obtained through the use of promo codes by you or any other user in the event that <b>Feelaxo.com</b> determines or believes that the use or redemption of the promo code was in error, fraudulent, illegal, or in violation of the applicable promo code terms or these terms.
                </p>
                <br />
                <h2><b className='font-medium text-lg font-semibold'>Intellectual property rights</b></h2><br />
                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  Feelaxo.com grants you a limited, personal, non-transferable, nonexclusive, revocable license to access and use the site pursuant to this agreement and to any additional terms and policies set forth by Feelaxo.com. All intellectual property rights (including all copyright, patents, trademarks, service marks, trade names, designs (including the “look and feel” and other visual or non-literal elements)) whether registered or unregistered) in the website or mobile application and service, (subject to the title & ownership rights as mentioned below) information content on the website or mobile application or accessed as part of the service, any database operated by us and all the website design, text and graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software (including applets and scripts) shall remain our property (or that of our licensors). You shall not, and shall not attempt to, obtain any title to any such intellectual property. All rights are reserved.<br />
                  None of the material listed in the clause above may be reproduced or redistributed or copied, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, sold, rented or sub-licensed, used to create derivative works, or in any way exploited without our prior express written permission. You may, however, retrieve and display the content of the website on a computer screen, store such content in electronic form on disk (but not on any server or other storage device connected to a network) or print / save one copy of such content for your own personal, non-commercial use, provided you keep intact all and any copyright and proprietary notices. You may not otherwise reproduce, modify, copy or distribute or use for commercial purposes any of the materials or content on the website or mobile application without our permission.<br />
                  “Feelaxo.com”, the Feelaxo.com logos and variations thereof found on the website or mobile application are trademarks owned by Feelaxo.com and all use of these marks inures to the benefit of Feelaxo.com. All rights (including goodwill and, where relevant, trademarks) in Feelaxo.com’s name are owned by us (or our licensors). Other product and company names mentioned on the website or mobile application are the trademarks or registered trademarks of their respective owners.<br />
                  The title, ownership rights and intellectual property rights in and to the content accessed using the service is the property of the applicable content owner or merchant and may be protected by applicable copyright or other law. The agreement gives you no rights to such content.
                  Subject to the clause below, any material you transmit or post or submit to the website or mobile application (or otherwise to us) shall be considered (and we may treat it as) non-confidential and non-proprietary, subject to our obligations under data protection legislation or such legislation which provides for protection of electronic data under Indian laws. If for some reason, any part of that statement does not work as a matter of law, then for anything which you supply to us from whatever source (i.e. Via email, the website or otherwise) you grant us a royalty-free, perpetual, irrevocable, non-exclusive right to use, copy, modify, adapt, translate, publish and distribute world-wide any such material.<br />
                  All comments, suggestions, ideas, notes, drawings, concepts or other information: (i) disclosed or offered to us by you; or (ii) in response to solicitations by us regarding the service or the website or mobile application; (in each foregoing case, these are called “<b>ideas</b>“) shall be deemed to be and shall remain our property and you hereby assign by way of present and future assignment all intellectual property rights in ideas, to us. You understand and acknowledge that we have both internal resources and other external resources which may have developed or may in the future develop ideas identical to or similar to ideas and that we are only willing to consider Ideas on these terms. In any event, any ideas are not submitted in confidence and we assume no obligation, express or implied by considering it. Without limitation, we shall exclusively own all now known or hereafter existing rights to the ideas of every kind and nature throughout the world and shall be entitled to unrestricted use of the ideas for any purpose whatsoever, commercial or otherwise without compensation to the provider of the ideas.<br />
                </p><br />

                <h2><b className='font-medium text-lg font-semibold'>Disclaimer of warranty; limitation of liability</b></h2><br />
                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  The user expressly agrees that use of the website or mobile application is at the user’s sole risk. Neither <b>Feelaxo.com</b>, its holding or subsidiaries, business associates nor any of their respective employees, agents, and third-party content providers warrant that use of the website or mobile application will be uninterrupted or error-free; nor do they make any warranty as to (i) the results that may be obtained from use of this website or mobile application, or (ii) the accuracy, reliability or content of any information, service or merchandise provided through the website or mobile application.
                  <br />The website or mobile application are made accessible on an “as is” basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to this agreement.<br />
                  We do not give any warranty that the services or the website or mobile application are free from viruses or anything else which may have a harmful effect on any technology.<br />
                  We reserve the right to change, modify substitute, suspend or remove without notice any information or voucher or service from the website or mobile application forming part of the service from time to time.  Your access to the website or mobile application and/or the services may also be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services.  We will attempt to restore such access as soon as we reasonably can.  We assume no responsibility for functionality which is dependant on your browser or other third-party software to operate.  For the avoidance of doubt, we may also withdraw any information or voucher from the website or services at any time.<br />
                  We reserve the right to block access to and/or to edit or remove any material which in our reasonable opinion may give rise to a breach of this agreement.
                  This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortuous behavior, negligence, or under any other cause of action. The user specifically acknowledges that Feelaxo.com is not liable for the defamatory, offensive or illegal conduct of other users or third-parties and that the risk of injury from the foregoing rests entirely with the user.<br />
                  In no event shall Feelaxo.com, or any business associates, third party content providers, third-party advertisers or third-party service providers, producing or distributing the website or the contents hereof, mobile application and any software, be liable for any damages, including, without limitation, direct, indirect, incidental, special, consequential or punitive damages arising out of the use of or inability to use the website or mobile application. <br />
                  The user hereby acknowledges that the provisions of this clause shall apply to all content on the website and mobile application.<br />
                  In addition to the terms set forth above, neither Feelaxo.com, nor its subsidiaries and business associates, third-party service providers or third-party content providers shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or untimeliness or unauthenticity of, the information contained within the website, or for any delay or interruption in the transmission thereof to the user, or for any claims or losses arising therefrom or occasioned thereby. None of the foregoing parties shall be liable for any third-party claims or losses of any nature, including without limitation lost profits, punitive or consequential damages.<br />
                  We will not be liable for indirect, special, or consequential damages (or any loss of
                  revenue, profits, or data) arising in connection with this agreement, even if we have been
                  advised of the possibility of such damages. Further, our aggregate liability arising with
                  respect to this agreement will not exceed the total offer price paid or payable to you under this agreement.<br /><br />

                  Feelaxo.com shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any transaction, on account of the cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.
                  <br /><br />
                </p>

                <h2><b className='font-medium text-lg font-semibold'>Monitoring</b></h2><br />
                <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                  Feelaxo.com shall have the right, but not the obligation, to monitor the content of the website or mobile application at all times, including any chat rooms and forums that may hereinafter be included as part of the website or mobile application, to determine compliance with this Agreement and any operating rules established by Feelaxo.com, as well as to satisfy any applicable law, regulation or authorized government request. Without limiting the foregoing, Feelaxo.com shall have the right to remove any material that Feelaxo.com, in its sole discretion, finds to be in violation of the provisions hereof or otherwise objectionable.
                  <br /><br />
                  <b className='font-medium text-lg font-semibold'>Privacy</b><br /><br />
                  The user acknowledges that all discussion for ratings, comments, bulletin board service, chat rooms and/or other message or communication facilities (collectively “<b>Communities</b>”) are public and not private communications, and that, therefore, others may read the user’s communications without the user’s knowledge. Feelaxo.com does not control or endorse the content, messages or information found in any communities, and, therefore, Feelaxo.com specifically disclaims any liability concerning the communities and any actions resulting from the user’s participation in any communities, including any objectionable content. Generally, any communication which the user posts on the website (whether in chat rooms, discussion groups, message boards or otherwise) is considered to be non-confidential. If particular web pages permit the submission of communications that will be treated by Feelaxo.com as confidential, that fact will be stated on those pages. By posting comments, messages or other information on the website or mobile application, the user grants Feelaxo.com the right to use such comments, messages or information for promotions, advertising, market research or any other lawful purpose. For more information see Feelaxo.com’s privacy policy.
                  <br /><br />

                  <b className='font-medium text-lg font-semibold'>License grant</b><br /><br />
                  <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                    By posting communications on or through this website or mobile application, the user shall be deemed to have granted to Feelaxo.com, a royalty-free, perpetual, irrevocable & non-exclusive license to use, reproduce, modify, publish, edit, translate, distribute, perform, and display the communication alone or as part of other works in any form, media, or technology whether now known or hereafter developed, and to sublicense such rights through multiple tiers of sublicenses.
                  </p><br /><br />
                  <b className='font-medium text-lg font-semibold'>Indemnification</b><br /><br />
                  <p>The user agrees to defend, indemnify and hold harmless Feelaxo.com, its subsidiaries and business associates, and their respective directors, officers, employees and agents from and against all claims and expenses, including attorneys’ fees, arising out of (i) the use of this website and/or the mobile application by the user; (ii) the use of the voucher; (iii) the use of the service or website or mobile application through your password; or (iv) any breach of this agreement by you.
                  </p><br /><br />

                  <b className='font-medium text-lg font-semibold'>Termination</b><br /><br />
                  <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                    Feelaxo.com may terminate this agreement at any time. Without limiting the foregoing, <b>Feelaxo.com</b> shall have the right to immediately terminate any passwords or accounts of a user in the event of any conduct by the user which <b>Feelaxo.com</b>, in its sole discretion, considers being unacceptable, or in the event of any breach by the user of this agreement.
                    One user can have only one account with a unique e-mail id and a unique phone number. If <b>Feelaxo.com</b> has any suspicion or knowledge that any of its users have created multiple accounts  with different e-mail addresses or phone numbers (including but not limited to account creation by using false names or providing misleading data for creating those accounts or using disposable email addresses or disposable phone numbers) to generate additional cashback credits or misuse or take undue advantage of the promotional benefits being provided on the <b>Feelaxo.com</b> platform, then <b>Feelaxo.com</b> may while reserving its rights to initiate civil and/or criminal proceedings against such user may also at its sole discretion terminate, suspend, block, restrict, cancel the account of such user and/or disqualify that user and any related users from availing the services ordered or undue benefits availed through these accounts.
                    All such provisions wherein the context so require, including clauses on intellectual property rights, disclaimer of warranty, limitation of liability, license grant, indemnification and termination above will survive termination of this agreement.
                    Our right to terminate this agreement shall not prejudice any other right or remedy we may have in respect of any breach or any rights, obligations or liabilities accrued prior to termination.
                  </p><br /><br />

                  <b className='font-medium text-lg font-semibold'>Third-party content</b><br /><br />
                  <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                    <b>Feelaxo.com</b>, similar to an internet service provider, is a distributor (and not a publisher) of content supplied by third parties and users. Accordingly, <b>Feelaxo.com</b> has does not have editorial control over such content. Any opinions, advice, statements, services, offers, or other information or content expressed or made available by third parties, including third-party content providers, or any other users are those of the respective author(s) or distributors and not of <b>Feelaxo.com</b>.
                    Neither Feelaxo.com nor any third-party provider of information guarantees the accuracy, completeness, or usefulness of any content, nor its merchantability or fitness for any particular purpose (refer to the clause for ‘disclaimer of warranty, limitation of liability’ above for the complete provisions governing limitation of liabilities and disclaimers of warranty).
                    In many instances, the content available through this website or mobile application represents the opinions and judgments of the respective information provider, user, or other users not under contract with <b>Feelaxo.com. Feelaxo.com</b> neither endorses nor is responsible for the accuracy or reliability of any opinion, advice or statement made on the website by anyone other than authorized <b>Feelaxo.com</b> employee/spokespersons while acting in official capacities.
                    Under no circumstances will Feelaxo.com be liable for any loss or damage caused by the user’s reliance on information obtained through the website. It is the responsibility of the user to evaluate the accuracy, completeness or usefulness of any information, opinion, advice, etc. Or other content available through the website.
                    The website contains links to third-party websites maintained by other content providers. These links are provided solely as a convenience to you and not as an endorsement by Feelaxo.com of the contents on such third-party websites and Feelaxo.com hereby expressly disclaims any representations regarding the content or accuracy of materials on such third-party websites. If the user decides to access linked third-party websites, the user does so at his/her own risk.
                    Unless you have executed a written agreement with <b>Feelaxo.com</b> expressly permitting you to do so, you may not provide a hyperlink to the website from any other website.<b> Feelaxo.com </b>reserves the right to revoke its consent to any link at any time in its sole discretion.
                  </p><br /><br />
                  <b>Advertisement</b><br /><br />
                  <p className='font-poppins font-helvetica font-sans font-normal text-base'>
                    Feelaxo.com may place advertisements in different locations on the website and at different points during the use of the service. These locations and points may change from time to time – but we will always clearly mark which goods and services are advertisements (i.e. From persons other than us) so that it is clear to you which goods and services are provided on an objective basis and which are not (i.e. the advertisements).<br />
                    You are free to select or click on advertised goods and services or not as you see fit.<br />
                    Any advertisements may be delivered on our behalf by a third party advertiser.
                    No personal data (for example your name, address, email address or telephone number) will be used during the course of serving our advertising, but, on our behalf, our third-party advertiser or affiliate may place or recognize a unique “cookie” on your browser (see our privacy policy about this). This cookie will not collect personal data about you nor is it linked to any personal data about you. If you would like more information about this practice and to know your choices about not having this information used by any company, see our privacy policy for more information<br />.
                  </p><br /><br />
                  <b>Miscellaneous</b><br /><br />
                  <p>This agreement, our terms of sale and our privacy policy (including our cookie policy) constitutes the entire agreement between the parties with respect to the subject matter hereof. No representation, undertaking or promise shall be taken to have been given or be implied from anything said or written in negotiations between the parties prior to this agreement except as expressly stated in this agreement. Neither party shall have any remedy in respect of any untrue statement made by the other upon which that party relied on when entering into this agreement (unless such untrue statement was made fraudulently or was as to a matter fundamental to a party’s ability to perform this agreement) and that party’s only remedies shall be for breach of contract as provided in this agreement. However, the service is provided to you under our operating rules, policies, and procedures as published from time to time on the website.
                    No waiver by either party of any breach or default hereunder is a waiver of any preceding or subsequent breach or default.
                    The section headings used herein are for convenience only and shall be of no legal force or effect.
                    If any provision of this agreement is held invalid by a court of competent jurisdiction, such invalidity shall not affect the enforceability of any other provisions contained in this agreement and the remaining portions of this agreement shall continue in full force and effect. The failure of either party to exercise any of its rights under this agreement shall not be deemed a waiver or forfeiture of such rights or any other rights provided hereunder.
                    All provisions of this agreement apply equally to and are for the benefit of<b> Feelaxo.com</b>, it subsidiaries, any holding companies of <b>Feelaxo.com</b>, its (for their) affiliated and it (or their) third party content providers and licensors and each shall have the right to assert and enforce such provisions directly or on its own behalf (save that this agreement may be varied or rescinded without the consent of those parties).
                  </p><br /><br />

                  <b className='font-medium text-lg font-semibold'>Products/services takedown policy: reporting a violation of infringement</b><br /><br />
                  <p>We want to list the products and services of genuine sellers on our website or mobile application in order to ensure that infringing products are removed from the site, as they affect your and genuine seller’s valuable trust.<br /><br />
                    We do not and cannot verify if the sellers have the right or ability to sell or distribute their listed products or services. However, we are committed to removing any infringing or unlicensed product or service once an authorized representative of the rights owner properly reports them to us. We sincerely want to ensure that item listings do not infringe upon the copyright, trademark or other intellectual property rights of third parties. We have the ability to identify and request the removal of allegedly infringing products and materials. Any person or company who holds intellectual property rights (such as a copyright, trademark or patent) which may be infringed upon by-products listed on Feelaxo.com is encouraged to report the same to the Grievance Officer.
                  </p><br /><br />

                  <b className='font-medium text-lg font-semibold'>CONTACT US</b><br /><br />
                  <p>
                    In accordance with Information Technology Act 2000 and rules made thereunder, if you have any questions regarding our Privacy Policy or our Terms of Sale or Terms of Use of the site, you may contact our Grievance Officer at the details mentioned below:
                    <br /><br />
                    <b>Address</b>:
                    Feelaxo.com,
                    Office No.803 D Mart Rd, Sector 15, Kharghar<br />
                    Navi Mumbai, Maharashtra 410210
                    <br /><br />
                    <b>Email</b>: hello@feelaxo.com
                  </p>
                </p>
              </p>
            </div>
          </div>


        </div>
      </div>

    </>
  )
}
