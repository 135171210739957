import MainNav1 from "components/Header/MainNav1";

const SiteHeader = () => {
  return (
    <>
    <div className="nc-Header sticky top-0 w-full left-0 right-0 z-50 nc-header-bg shadow-sm dark:border-b dark:border-neutral-700">
      <MainNav1></MainNav1>
    </div>
    </>
  );
};

export default SiteHeader;
