import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaStar } from "react-icons/fa";

interface GoogleReviewProps {
    business_id: number;
}

interface GoogleReview {
    author_name: string;
    author_url: string;
    profile_photo_url: string;
    rating: number;
    relative_time_description: string;
    text: string;
}

const GoogleReview: React.FC<GoogleReviewProps> = ({ business_id }) => {
    const [reviews, setReviews] = useState<GoogleReview[]>([]);
    const [totalRating, setTotalRating] = useState<number>(0);
    const [ratingCount, setRatingCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(true);

    const reviewsPerPage = 5;

    useEffect(() => {
        setLoading(true);
        setError(null);

        axios.post("/api/business/google/review", { business_id, page: currentPage, reviewsPerPage })
            .then((response) => {
                setReviews((prevReviews) => [...prevReviews, ...response.data.reviews]);
                setTotalRating(response.data.totalRatings);
                setRatingCount(response.data.RatingCount);
                setHasMore(response.data.hasMore);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching Google reviews:", error);
                setError("Failed to load reviews");
                setLoading(false);
            });
    }, [business_id, currentPage]);

    const loadMoreReviews = () => {
        if (hasMore) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    if (loading && currentPage === 1) {
        return <p className="text-gray-500 text-center">Loading reviews...</p>;
    }

    if (error) {
        return <p className="text-red-500 text-center">{error}</p>;
    }

    return (
        <div className="max-w-4xl mx-auto mt-10">
            {reviews.length > 0 ? (
                <div>
                    <h2 className="text-2xl font-semibold mb-6 text-gray-900">Google Reviews</h2>
                    
                    <div className="mb-8">
                        <div className="flex items-center mb-2">
                            <FaStar className="w-8 h-8 text-yellow-400" />
                            <span className="text-3xl font-bold text-gray-900 ml-2">{totalRating.toFixed(1)}</span>
                        </div>
                        <p className="text-gray-600 text-lg">{ratingCount} Reviews</p>
                    </div>

                    <div className="space-y-6">
                        {reviews.map((review, index) => (
                            <div
                                key={index}
                                className="flex flex-col md:flex-row items-start md:items-center p-4 border border-gray-200 rounded-lg shadow-sm bg-white"
                            >
                                <img
                                    src={review.profile_photo_url}
                                    alt={review.author_name}
                                    className="w-16 h-16 rounded-full mr-4 mb-2 md:mb-0"
                                />
                                <div className="flex-1">
                                    <div className="flex items-center mb-2">
                                        <a
                                            href={review.author_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-lg font-medium text-blue-600"
                                        >
                                            {review.author_name}
                                        </a>
                                        <span className="text-gray-500 ml-4">
                                            {review.relative_time_description}
                                        </span>
                                    </div>
                                    <div className="flex items-center mb-2">
                                        {Array.from({ length: review.rating }, (_, i) => (
                                            <FaStar key={i} className="w-5 h-5 text-yellow-400" />
                                        ))}
                                    </div>
                                    <p className="text-gray-700">{review.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Load More Button */}
                    {hasMore && (
                        <div className="flex justify-center mt-6">
                            <button
                                onClick={loadMoreReviews}
                                className="px-4 py-2 border rounded-lg bg-blue-600 text-white hover:bg-blue-700"
                                disabled={loading}
                            >
                                {loading ? "Loading..." : "Load More Reviews"}
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <p className="text-gray-500 text-center">No reviews available.</p>
            )}
        </div>
    );
};

export default GoogleReview;
