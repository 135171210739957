import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import SelectCity from "containers/selectCity/selectCity";
import { FrontendData } from "../../types/types";
import { motion } from 'framer-motion';
import ListingMapFilters from "containers/SearchPage/ListingMapFilters";
import KeywordListingMapFilters from "containers/SearchPage/KeywordListingMapFilters";
import { Helmet } from 'react-helmet';



interface Business {
  id: number;
  business_name: string;
}

interface Result {
  id: number,
  value: string,
  type: string
}

const HeroSearchForm: React.FC<{ className?: string; fill?: string; closeModal?: () => void;}> = ({
  className,
  closeModal,
  fill,
 
  
}) => {
  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState<Business[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [activeBusinessType, setActiveBusinessType] = useState<number | null>(1);
  const [cityData, setCityData] = useState<FrontendData | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [results, setResults] = useState<Result[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [searchResultsState, setSearchResultsState] = useState(false);
 

  const navigate = useNavigate();

 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 720);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const getCookie = (cookieName: string): string | null => {
      const name = `${cookieName}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");

      for (let cookie of cookieArray) {
        let trimmedCookie = cookie.trim();
        if (trimmedCookie.indexOf(name) === 0) {
          return trimmedCookie.substring(name.length);
        }
      }

      return null;
    };

    const frontendDataCookie: FrontendData | null = JSON.parse(getCookie("frontendData") || "null");
    if (frontendDataCookie) setCityData(frontendDataCookie);
  }, []);

  useEffect(() => {
    axios.get("/api/services/businessTypes")
      .then((response) => setFilteredBusinessTypes(response.data))
      .catch((error) => console.error("Error fetching business types: ", error));
  }, []);

  useEffect(() => {
    axios.post("/api/search/query", {
      cityId: cityData?.city_id,
      businessType: activeBusinessType,
      search: searchTerm,
    })
      .then((response) => setResults(response.data))
      .catch((error) => console.error("Error fetching results: ", error));
  }, [cityData, activeBusinessType, searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const searchResultsContainer = document.getElementById("searchResultsContainer");
      if (searchResultsContainer && !searchResultsContainer.contains(event.target as Node)) {
        setSearchResultsState(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleTabClick = (businessTypeId: number) => setActiveBusinessType(businessTypeId);

  const openCityPopup = () => {
    document.cookie.split(";").forEach((cookie) => {
      const [cookieName] = cookie.trim().split("=");
      if (cookieName === "frontendData") {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    });
    setIsVisible(true);
  };

  const handleClose = () => setIsVisible(false);

  const handleUpdateVisibility = (newVisibility: boolean) => setIsVisible(newVisibility);

  const ShowResults = () => {
    const query = searchTerm ? `query=${searchTerm}&type=${activeBusinessType}` : `type=${activeBusinessType}`;
    window.location.href = `/listing?${query}`;
  };

  const makeSEOUrl = async (businessName: string, businessId: number, businessType: number) => {
    let url = `/venue/`;
    let bType: string = '';

    if (businessType === 1) bType = "spa";
    else if (businessType === 2) bType = "salon";
    else if (businessType === 3) bType = "wellness";
    else {
      try {
        const response = await axios.post("/api/business/post/business/type", { businessId });
        bType = response.data;
      } catch (error) {
        console.error("Error fetching business type:", error);
      }
    }

    const sanitizedCityName = cityData?.city_name.replace(/\s/g, "-");
    const sanitizedBusinessName = businessName.replace(/\s/g, "-");
    return `${url}${bType}/${sanitizedCityName}/${sanitizedBusinessName}?id=${businessId}`;
  };

  



  return (
  
    
    <>
  
      <SelectCity
        isVisible={isVisible}
        onClose={handleClose}
        onCitySelect={() => { /*do nothing*/ }}
        updateParentVisibility={handleUpdateVisibility}
      />
      <div className="z-40 relative mx-auto md:mt-8 rounded-lg p-4 drop-shadow-md sm:w-[90%] md:w-[80%]">
        <div className={`${isMobile ? "" : "flex justify-between"}`}>
          <div className={`${isMobile ? "flex space-x-2 mx-auto align-center items-center justify-center" : "flex space-x-4"}`}>
            {filteredBusinessTypes.map((businessType) => (
              businessType.id !== 11 && (
                <motion.div
                  key={businessType.id}
                  className={`cursor-pointer ${className} flex justify-center items-center md:w-28 px-4 py-2 sm:px-2 md:my-6 rounded-full ${activeBusinessType === businessType.id ? `bg-black border border-black text-white` : 'border'}`}
                  onClick={() => handleTabClick(businessType.id)}
                  whileHover={{ scale: 1.1 }}
                >
                  {businessType.business_name === "Wellness Center" ? "Wellness" : businessType.business_name}
                </motion.div>
              )
            ))}

            {isMobile && (
              <motion.div
                onClick={openCityPopup}
                className={`${className} cursor-pointer rounded-full text-center flex items-center justify-center border bg-white border p-2 m-4 text-black border-gray-500 hover:bg-gray-100`}
                whileHover={{ scale: 1.1 }}>
                <svg width="24" height="24" fill="black" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                  <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
                </svg>
                {!isMobile && <>Location</>}
              </motion.div>
            )}
          </div>

          {!isMobile && (
            <motion.div
              onClick={openCityPopup}
              className={`${className} group hover:text-white cursor-pointer rounded-full text-center flex items-center justify-center border md:w-28 px-4 py-2 sm:px-2 md:my-6 border-gray-500 hover:bg-black`}
              whileHover={{ scale: 1.1 }}
            >
              <svg 
                width="24" 
                height="24" 
                fill={`${fill}`} 
                xmlns="http://www.w3.org/2000/svg" 
                fillRule="evenodd" 
                clipRule="evenodd"
                className="group-hover:fill-white"
              >
                <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602" />
              </svg>
              Location
            </motion.div>
        )}

        </div>

        <div className="bg-white rounded-full" style={{ border: "0.1vw solid #d3d3d3", display: "grid", gridTemplateColumns: "9.5fr 0.5fr", padding: "0.5vw" }}>
          <input
            id="searchResultsContainer"
            type="text"
            placeholder="Search for Venues or Services ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={() => setSearchResultsState(true)}
            className="border-none focus:outline-none outline-none mr-8"
            style={{ outline: "none!important" }}
          />
          <button onClick={ShowResults} className="bg-red-400 text-white p-4 rounded-full" style={{ textAlign: "center", display: "grid", alignItems: "center", justifyContent: "centerx" }}>
            <svg className="w-6 h-6" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(255,255,255)">
              <path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fillRule="nonzero" />
            </svg>
          </button>
        </div>
    
       
    
    

       

        <div className={`bg-white w-[90%] sm:mx-auto md:ml-6 ml-4 z-40 absolute rounded-3xl mt-2 left-0 right-0 shadow-xl searchResults ${searchResultsState ? "block" : "hidden"}`}>
          {results.slice(0, 4).map((result: Result, index) => (
            <React.Fragment key={index}>
              {result.type === 'business' ? (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    makeSEOUrl(result.value, result.id, 0).then((url) => {
                      if (closeModal) closeModal();
                      navigate(url);
                    }).catch((error) => console.error("Error creating SEO URL:", error));
                  }}
                  className="block"
                >
                  <div className="md:p-4 p-2 m-4 rounded-md cursor-pointer hover:bg-gray-100">
                    <div>
                      <div className="gray-800 text-md">{result.value}</div>
                      <div className="gray-400 text-sm">{result.type === "business" ? "venue near you" : "service"}</div>
                    </div>
                  </div>
                </a>
              ) : (
                <div onClick={closeModal}>
                  <Link to={`/listing?query=${result.value}`}>
                    <div className="p-4 m-4 rounded-md cursor-pointer hover:bg-gray-100">
                      <div className="gray-800 text-md">{result.value}</div>
                      <div className="gray-400 text-sm">service</div>
                    </div>
                  </Link>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
   
    </>
  );
};

export default HeroSearchForm;
