import { FC, useEffect, useState, useMemo, useCallback } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import NextPrev from "shared/NextPrev/NextPrev";
import useNcId from "hooks/useNcId";
import { BusinessData, FrontendData } from "../../types/types";
import StartRating from "components/StartRating/StartRating";
import Badge from "shared/Badge/Badge";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  className = "",
  itemClassName = "",
  itemPerRow = 4,
  sliderStyle = "style1",
}) => {
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + useNcId();

  const MY_GLIDEJS = useMemo(
    () =>
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 10,
        bound: true,
        breakpoints: {
          1280: { perView: itemPerRow - 1 },
          1024: { gap:35, perView: itemPerRow - 1 },
          768: { gap: 10, perView: itemPerRow - 2 },
          640: { gap: 10, perView: itemPerRow - 2 },
          500: { gap: 10, perView: 1 },
        },
      }),
    [UNIQUE_CLASS, itemPerRow]
  );

  const [businesses, setBusinesses] = useState<BusinessData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [City, setCity] = useState<FrontendData | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const cookieValue = document.cookie;
      const match = cookieValue.match(/frontendData=([^;]*)/);
      if (!match) throw new Error("Error: frontendData cookie not found");

      const decodedValue = decodeURIComponent(match[1]);
      const { city_id }: FrontendData = JSON.parse(decodedValue);
      if (!city_id) throw new Error("Error: Invalid city_id");

      const response = await fetch("/api/business/bestdeals", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ city_id }),
      });

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.json();
      if (data.error) {
        console.error("Error from backend:", data.error);
        throw new Error(data.error);
      } else {
        setBusinesses(data);
      }
    } catch (error: any) {
      setError("Error fetching data: " + error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const cookieValue = document.cookie;
    const match = cookieValue.match(/frontendData=([^;]*)/);
    if (match) {
      setCity(JSON.parse(match[1]));
      fetchData();
    } else {
      const interval = setInterval(() => {
        const cookieValue = document.cookie;
        const match = cookieValue.match(/frontendData=([^;]*)/);
        if (match) {
          setCity(JSON.parse(match[1]));
          fetchData();
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [fetchData]);

  useEffect(() => {
    if (!loading) MY_GLIDEJS.mount();
  }, [loading, MY_GLIDEJS]);

  const shortenAddress = useCallback((address: string) => {
    return address.length > 12 ? address.split(",")[0].slice(0, 12) : address;
  }, []);

  const makeSEOUrl = useCallback(
    (businessName: string, businessId: number, businessType: number) => {
      const bType = businessType === 1 ? "spa" : businessType === 2 ? "salon" : "wellness";
      const sanitizedCityName = City?.city_name.replace(/\s/g, "-");
      const sanitizedBusinessName = businessName.replace(/\s/g, "-");
      return `/venue/${bType}/${sanitizedCityName}/${sanitizedBusinessName}?id=${businessId}`;
    },
    [City]
  );

  const renderCard = useCallback(
    (business: BusinessData) => (
      <div
        key={business.id}
        className="nc-StayCard w-64 relative hover:shadow-lg bg-white dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform"
        aria-label={`${business.business_name} - ${business.business_type === 1 ? "spa" : business.business_type === 2 ? "salon" : "wellness"} in ${City?.city_name}`}
      >
        <a 
          href={makeSEOUrl(business.business_name, business.id, business.business_type)}
          target="_blank"
          className="block"
          title={`${business.business_name} - Best ${business.business_type === 1 ? "Spa" : business.business_type === 2 ? "Salon" : "Wellness Center"} Deals`}
        >
          <div className="relative w-full h-44 overflow-hidden">
            <img
              src={business.thumbnail || "https://nestcraft.sirv.com/Feelaxo/placeholder-small.png"}
              alt={`${business.business_name} - ${business.business_type === 1 ? "Spa" : business.business_type === 2 ? "Salon" : "Wellness Center"} in ${City?.city_name}`}
              title={`${business.business_name} in ${City?.city_name}`}
              className="w-full h-full object-cover"
            />
            <div className="absolute flex w-full p-4 pt-6 z-50 h-10 items-center justify-between">
              <div>
                <Badge name={business.business_type === 1 ? "spa" : business.business_type === 2 ? "salon" : "Wellness"} />
              </div>
              <BtnLikeIcon business_id={business.id} />
            </div>
          </div>
          <div className="p-4 space-y-4">
            <div className="space-y-2">
              <h2 className="font-medium capitalize text-lg line-clamp-1">{business.business_name}</h2>
              <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span>{`${shortenAddress(business.address)}, ${City?.city_name}`}</span>
              </div>
            </div>
            <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
            <div className="flex justify-between items-center">
              <div
                className={`${
                  business.status === "open" ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"
                } px-4 py-1 rounded-full`}
              >
                <p>{business.status === "open" ? "Open" : "Closed"}</p>
              </div>
              <StartRating point={business.rating} reviewCount={business.total_rating} />
            </div>
          </div>
        </a>
      </div>
    ),
    [City, makeSEOUrl, shortenAddress]
  );
  
  
  

  if (loading) return <div className="grid w-12 mt-4 mx-auto">Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={`nc-SectionSliderNewCategories mb-8 mt-8 xl:rounded-[40px] ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root flex flex-wrap`}>
        <Heading desc="checkout best deals venues" hasNextPrev={sliderStyle === "style1"} isCenter={sliderStyle === "style2"}>
          Best Deals
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides flex flex-wrap">
            {businesses.map((business) => (
              <li key={business.id} className={`glide__slide ${itemClassName} flex-grow`}>
                {renderCard(business)}
              </li>
            ))}
          </ul>
        </div>
        {sliderStyle === "style2" && <NextPrev className="justify-center mt-16" />}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;