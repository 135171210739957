import React, { useEffect, useState } from 'react';
import axios from "axios";
import { FaStar } from 'react-icons/fa';

interface ReviewProps {
    business_id: number;
    total_review: number;
    rating: number;
}

interface BusinessReview {
    hygiene: number;
    services: number;
    staff: number;
    value: number;

}

const Review: React.FC<ReviewProps> = ({ business_id, total_review, rating }) => {
    const [reviews, setReviews] = useState<BusinessReview>({
        hygiene: 0,
        services: 0,
        staff: 0,
        value: 0,
    });

    useEffect(() => {
        fetchReviews();
    }, []);

    const fetchReviews = async () => {
        try {
            const response = await axios.post("/api/business/rating/all", { business_id });
            setReviews(response.data);
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    };

    const renderRatingStars = (rating: number) => {
        const roundedRating = Math.round(rating);
        const filledStarsCount = Math.floor(roundedRating); 
        const emptyStarsCount = 5 - filledStarsCount; 
    
        const filledStars = Array.from({ length: filledStarsCount }, (_, index) => (
            <FaStar key={index} className="rounded-full ml-1 h-6 w-6 text-black"></FaStar>
        ));
    
        const emptyStars = Array.from({ length: emptyStarsCount }, (_, index) => (
            <FaStar key={filledStarsCount + index} className=" ml-1 h-6 w-6 text-gray-300"></FaStar>
        ));
    
        // If there is a fractional part, add a partially filled star
        if (rating !== roundedRating) {
            const partialStar = (
                <FaStar key="partial" className="ml-1 h-6 w-6 from-black to-gray-200" style={{ backgroundSize: `${(rating - filledStarsCount) * 100}% 100%` }}></FaStar>
            );
            return [...filledStars, partialStar, ...emptyStars.slice(1)];
        }
    
        return [...filledStars, ...emptyStars];
    };
    

    const renderRatingBar = (rating: number) => {
        const barWidth = `${rating * 40}px`;
        return (
            <div className="rounded-full bg-gray-200 h-6" style={{width: '200px'}}>
                <div className="rounded-full bg-gray-800 h-6" style={{ width: barWidth }}></div>
            </div>
        );
    };

    return (
        <>
            <div className="flex items-baseline">
                <div className="text-4xl">{rating}</div>
                {renderRatingStars(rating)}
                <div className="text-3xl ml-4 font-thin">{total_review} reviews</div>
            </div>

            <div className="md:w-96">
                {Object.entries(reviews).map(([criteria, score]) => (
                    <div key={criteria} className="grid w-full my-3" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <div className="mr-2 capitalize">{criteria}</div>
                        {renderRatingBar(score)}
                        <div className="ml-2">{score}</div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Review;
