import React, { FC, useState, useEffect } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
// import SocialsList from "shared/SocialsList/SocialsList";

export interface NavMobileProps {
  onClickClose?: () => void;
}

const NavMobile: FC<NavMobileProps> = ({ onClickClose }) => {

  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const checkUserIdCookie = () => {
      const cookies = document.cookie.split(';');
      for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name.trim() === 'userId' && value) {
          return true;
        }
      }
      return false;
    };

    setLoggedIn(checkUserIdCookie());
  }, []);


  return (

    <>
      <div className="bg-white h-full w-full position-fixed p-8 right-0 l-0 t-0 r-0">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "9.5fr 0.5fr",
            width: "100%",
          }}
        >
          <div className="text-xl drop-shadow-lg text-black font-bold">
            <Logo />
          </div>
          <div>
            <ButtonClose onClick={onClickClose} />
          </div>
        </div>


        {
          !(loggedIn) ? (
              <div className="flex flex-col mx-auto mt-10 justify-center items-center">
                <div className="flex flex-col items-center">
                  <a title="Feelaxo - Log into your account" href="/login">
                    <button className="w-72 p-3 rounded-full text-white bg-red-500">Log In</button>
                  </a>
                </div>
                <div className="flex flex-col items-center mt-2 ml-4">
                  <div> New user - <a className="underline" title="Feelaxo - Create an account" href="/signup">Register</a></div>
                </div>
              </div>
          ) : (
            <div className="flex flex-col mt-5 mb-5">
            <h1 className="font-bold text-xl mb-2">Account</h1>
            
            <div>
              <a href="/dashboard" title="Dashboard">
                <div className="mb-2 hover:text-red-500">My Account</div>
              </a>
            </div>
  
            <div>
              <a href="/orders" title="Booked - Orders">
                <div className="mb-2 hover:text-red-500">Booking History</div>
              </a>
            </div>
            <div>
              <a href="/Wishlist" title="Wish List">
                <div className="mb-2 hover:text-red-500">Wishlist</div>
              </a>
            </div>
            <div>
              <a href="/logout">
                <div className="mb-2 hover:text-red-500">Log Out</div>
              </a>
            </div>
          </div>
          )
        }

        

        <div className="flex flex-col mt-5 mb-5">
          <h1 className="font-bold text-xl mb-2">Explore</h1>
          
          <div>
            <a title="Top rated salons in your city" href="/listing?query=&type=3">
              <div className="mb-2 hover:text-red-500">Salon</div>
            </a>
          </div>

          <div>
            <a title="Top rated spa in your city" href="/listing?query=&type=1">
              <div className="mb-2 hover:text-red-500">Spa</div>
            </a>
          </div>

          <div>
            <a title="Top rated wellness centers in your city" href="/listing?query=&type=4">
              <div className="mb-2 hover:text-red-500">Wellness</div>
            </a>
          </div>
        </div>

        <hr />

        <div className="flex flex-col mt-5">
          <h1 className="font-bold text-xl mb-2">For Business</h1>
          
          <div>
            <a title="Feelaxo - Business Login" href="https://pos.feelaxo.com">
              <div className="mb-2 hover:text-red-500">Log In</div>
            </a>
          </div>

          <div>
            <a title="Feelaxo - List your business" href="https://pos.feelaxo.com/admin/register">
              <div className="mb-2 hover:text-red-500">List your business</div>
            </a>
          </div>
        </div>


       
      </div>
    </>
  );
};

export default NavMobile;