import React, { useState } from 'react';

interface AccordionProps {
  title: string;
  answer: React.ReactNode; // Update answer to accept JSX
}

const Accordion: React.FC<AccordionProps> = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <div className="py-2">
      <button onClick={() => setAccordionOpen(!accordionOpen)} className="flex justify-between w-full">
        <span className="text-left">
          <h4 className="text-left">{title}</h4>
        </span>

        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${
            accordionOpen ? 'rotate-180' : ''
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen ? 'grid-rows-[1fr] opacity-100 mt-2' : 'grid-rows-[0fr] opacity-0'
        }`}
      >
        <div className="overflow-hidden font-semibold text-left">
          <h3 className="text-left">{answer}</h3>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
