import React, { useEffect, useState } from "react";
import { BusinessData } from '../../../types/types';
import Amenities from "./Amenities";

//map settings
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';

interface AboutUsProps {
  businessData: BusinessData;
}

interface FrontendData {
  city_id: number;
  city_name: string;
  business_count: number;
  lat: number,
  long: number,
}

const AboutUs: React.FC<AboutUsProps> = ({ businessData }) => {
  const [frontendData, setFrontendData] = useState<FrontendData | null>(null);

  useEffect(() => {
    const getCookie = (name: string): string | null => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${name}=`)) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };


    const frontendDataCookie = getCookie('frontendData');
    if (frontendDataCookie) {
      try {
        const parsedFrontendData: FrontendData = JSON.parse(frontendDataCookie);
        setFrontendData(parsedFrontendData);
      } catch (error) {
        console.error('Error parsing frontendData cookie:', error);
      }
    } else {
      console.log('Cookie not found or has no value');
    }
  }, []);

  const customIcon = new L.DivIcon({
    className: 'custom-icon',
    html: '<svg xmlns="http://www.w3.org/2000/svg" style="fill: red;" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>',
    iconSize: [48, 48],
    iconAnchor: [12, 24],
    popupAnchor: [0, -24],
  });


  return (
    <div className="">

      {
        businessData.description && (
          <div className="listingSection__wrap">
        <h2 className=" text-xl font-semibold mr-5 md:mr-2">About {businessData.business_name}</h2>
        <div className="description mb-4 mt-4">{businessData.description}</div>
      </div>
        )
      }
      

      <h2 className="listingSection__wrap text-xl font-semibold mt-4 mb-4">Amenities
        <Amenities business_name={businessData.business_name} id={businessData.id} />
      </h2>
      {businessData.lat !== undefined && businessData.long !== undefined && (
        <div className="sticky xl:sticky top-0 mt-5 left-0 w-full md:h-96 h-80 rounded-lg overflow-hidden">
          <MapContainer
            center={[businessData.lat, businessData.long]}
            zoom={12}
            className="rounded-lg overflow-hidden"
            style={{ width: '100%', height: '100%' }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            <Marker key={-1} position={[businessData.lat, businessData.long]} icon={customIcon}>
              <Popup>
                <div>
                  <p style={{ margin: 0, fontWeight: 'bold' }}>{businessData.business_name}</p>
                </div>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
