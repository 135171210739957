import React, { useEffect, useState } from 'react';
import Accordion from './Accordian';
import axios from 'axios';
import { FaStar } from "react-icons/fa";
import { BusinessData, Staff } from '../../types/types';
import { useNavigate } from 'react-router-dom';

type FAQItem = {
  question: string;
  answer: JSX.Element | string;
};

interface FaqProps {
  businessData: BusinessData;
  get_type: string;
  get_location: string;
  get_services: { name: string }[];
  scrollToServices: () => void;
  scrollToBusinessHours: () => void;
}

const Faq: React.FC<FaqProps> = ({
  businessData,
  get_type,
  get_services,
  scrollToServices,
  scrollToBusinessHours
}) => {


  const $businessName = businessData.business_name;
  const $services = get_services.map((service) => service.name).join(', ');
  const $location = businessData.address;
  const $businessType = get_type;

  const faqData: FAQItem[] = [
    {
      question: `What services do you offer at ${$businessName}?`,
      answer: `We provide a range of hair, beauty, skincare, and wellness services tailored to ${$services}.`
    },
    {
      question: "Do I need to book an appointment, or do you accept walk-ins?",
      answer: (
        <>
          While we welcome walk-ins, we recommend booking an appointment to secure your preferred time. You can book our{' '}
          <span onClick={scrollToServices} className="text-blue-500 cursor-pointer underline">services</span> in just 3 clicks at Feelaxo.
        </>
      )
    },
    {
      question: "How can I book an appointment?",
      answer: `You can book an appointment through our website. Please check our list of ${$businessType} services to book a salon appointment at ${$businessName}.`
    },
    {
      question: "What should I do if I need to cancel or reschedule my appointment?",
      answer: "Please contact us at least 24 hours in advance to cancel or reschedule your appointment. You can log in to your account and cancel the appointment on the 'My Booking' page."
    },
    {
      question: "What are your salon hours?",
      answer: (
        <>
          Our working hours vary by day; normally we are open Mon-Fri, 10am - 8pm. Please check our {' '}
          <span onClick={scrollToBusinessHours} className="text-blue-500 cursor-pointer underline">
            Opening Hours
          </span> or call us for the most up-to-date schedule.
        </>
      )
    },
    {
      question: "Where are you located, and is parking available?",
      answer: `We are located at ${$location}. Public parking is available at the location.`
    },
    {
      question: `Do you offer services for men, women, and children?`,
      answer: `Yes, we offer services for men, women, and children of all ages. Check our list of ${$businessType} services and book an appointment.`
    },
    {
      question: "What are your prices for different services?",
      answer: (
        <>
          Please refer to our {' '}
          <span onClick={scrollToServices} className="text-blue-500 cursor-pointer underline">Pricing Menu</span> on the website or contact us directly for details.
        </>
      )
    },
    {
      question: "Do you offer any discounts or special packages?",
      answer: `Yes, we frequently offer seasonal specials and package deals. Follow us on social media or inquire at the ${$businessType} for current offers. We also send regular offer updates to clients on WhatsApp and email. To get regular updates, please subscribe.`
    },
    {
      question: "What forms of payment do you accept?",
      answer: "We accept cash, credit/debit cards, and digital payments like UPI & Gpay."
    },
    {
      question: "Is a deposit required for booking certain services?",
      answer: "For some services, we may require a deposit. We’ll inform you at the time of booking if a deposit is needed."
    },
    {
      question: "How long will my hair appointment take?",
      answer: (
        <>
          Appointment times vary by service. Please{' '}
          <span onClick={scrollToServices} className="text-blue-500 cursor-pointer underline">Check the service</span>{' '}
          duration when booking for an estimated time.
        </>
      )
    },
    {
      question: "Do you offer consultations for hair services?",
      answer: "Yes, we offer free consultations to discuss your hair goals and service options."
    },
    {
      question: "Can I bring my own hair products for use during my service?",
      answer: "Absolutely! We’re happy to accommodate special product requests."
    },
    {
      question: "What if I’m not satisfied with my hair service?",
      answer: "Your satisfaction is important to us. Please inform us within 7 days, and we’ll work to address your concerns."
    },
    {
      question: "How often should I come in for maintenance or touch-ups?",
      answer: "This depends on your style and hair type; we’re happy to provide recommendations after your service."
    },
    {
      question: "What skincare or facial treatments do you offer?",
      answer: (
        <>
          We offer various facials, peels, and skin treatments. Check our {' '}
          <span onClick={scrollToServices} className="text-blue-500 cursor-pointer underline">list of services</span>{' '}
          on the website or call us to learn more.
        </>
      )
    },
    {
      question: "Do you offer makeup services, including bridal or special occasion makeup?",
      answer: "Yes, our team offers makeup for all occasions, including weddings and events."
    },
    {
      question: "Do you offer hot waxing services?",
      answer: `Yes, hot waxing is available`
    },
    {
      question: "Are there any post-care instructions for skincare or waxing treatments?",
      answer: "Avoid sun exposure and hot showers for 24 hours post-treatment, and follow any additional guidelines provided."
    },
    {
      question: "What are your COVID-19 safety protocols?",
      answer: "We follow local health guidelines, sanitize equipment between clients, and require masks as necessary."
    },
    {
      question: "Are all tools and equipment sanitized between clients?",
      answer: "Yes, we maintain strict hygiene and sanitize all tools between appointments."
    },
    {
      question: "Do you accommodate clients with allergies or skin sensitivities?",
      answer: "Yes, please inform us of any sensitivities, and we will adjust products and services accordingly."
    },
    {
      question: "Do you offer bridal packages or event styling?",
      answer: "Yes, we offer customized bridal and event packages, including on-site styling."
    },
    {
      question: "Can I book multiple services in one appointment?",
      answer: "Absolutely! We’re happy to coordinate multiple services for your convenience."
    },
    {
      question: "Do you offer hair or beauty services at the client’s location or home?",
      answer: "Yes, we can travel to the client’s location for certain services."
    }
  ];

  return (
    <div>
      {faqData.map((faq, index) => (
        <div key={index} className="border rounded-lg p-2 bg-white mb-0" style={{ marginTop: '5px' }}>
          <Accordion title={faq.question} answer={faq.answer} />
        </div>
      ))}
    </div>
  );
};

export default Faq;
