import { useRef, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../images/massage1 (1).png';
import img2 from '../../images/facial.png';
import img3 from '../../images/hair-spa.png';
import img4 from '../../images/make-up.png';
import img5 from '../../images/haircut.png';
import img6 from '../../images/ayurveda.png';
import img7 from '../../images/hair colour.png';
import img8 from '../../images/facial-mask.png';
import img9 from '../../images/nail.png';
import img10 from '../../images/Beard.png';
import img11 from '../../images/eyebrow.png';
import img12 from '../../images/mehendi.png';
import img13 from '../../images/waxing1 (1).png';


import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTimeoutFn } from "react-use";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { Dialog, Tab, Transition } from "@headlessui/react";

interface MenuItem {
  id: number;
  name: string;
}

const images = [
  { src: img5, alt: 'Haircut', href: '/listing?query=HairCut' },
  { src: img4, alt: 'Makeup', href: '/listing?query=Makeup' },
  { src: img1, alt: 'Massage', href: '/listing?query=massage' },
  { src: img2, alt: 'Facial', href: '/listing?query=facial' },
  { src: img3, alt: 'Hair Spa', href: '/listing?query=hairSpa' },
  { src: img6, alt: 'Ayurveda', href: '/listing?query=Ayurveda' },
  { src: img7, alt: 'Haircolor', href: '/listing?query=HairColor' },
  { src: img8, alt: 'Masks', href: '/listing?query=Masks' },
  { src: img9, alt: 'Nail', href: '/listing?query=Nail' },
  { src: img10, alt: 'Shaving', href: '/listing?query=Shaving' },
  { src: img11, alt: 'Threading', href: '/listing?query=threading' },
  { src: img12, alt: 'Mehendi', href: '/listing?query=mehendi' },
  { src: img13, alt: 'Waxing', href: '/listing?query=Waxing' },

];
const menuItems: MenuItem[] = [
  { id: 1, name: "SPA" },
  { id: 2, name: "SALON" },
  { id: 3, name: "WELLNESS" },
];
const ListingMapFilters = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  const scrollLeft = () => {
    if (scrollRef.current) {

      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {

    if (scrollRef.current) {

      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [showModal, setShowModal] = useState(false);
  const [activeItem, setActiveItem] = useState<number>(1);

  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }


  return (
    <>
      <div className="fixed shadow-md lg:px-40 md:px-24 items-center justify-center md:top-24 flex bg-white left-0 z-40 mb-12 lg:mb-16 w-full flex-shrink-0 overflow-x-auto" style={{ backdropFilter: 'blur(10px)' }}>
        <div>
          <button onClick={scrollLeft} className='p-4 drop-shadow-md'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" className="block fill-none h-6 w-6 stroke-current stroke-5.33333 overflow-visible">
              <path fill="none" d="M20 28 8.7 16.7a1 1 0 0 1 0-1.4L20 4"></path>
            </svg>
          </button>
        </div>

        <div ref={scrollRef} className="flex w-[80%] space-x-4 pt-4 md:space-x-14 items-center flex-nowrap overflow-x-scroll scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200 w-full">
  {images.map((image, index) => (
    <div key={index} className="items-center block">
      <a href={image.href} title={image.alt}>
        <img title={image.alt} src={image.src} alt={image.alt} className="h-8 w-8 mx-auto" />
        <p className="font-semibold" style={{ fontSize: "10px", whiteSpace: "nowrap" }}>{image.alt}</p>
      </a>
    </div>
  ))}
        </div>

        <div>
          <button onClick={scrollRight} className="p-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" className="block fill-none h-6 w-6 stroke-current stroke-5.33333 overflow-visible">
              <path fill="none" d="m12 4 11.3 11.3a1 1 0 0 1 0 1.4L12 28"></path>
            </svg>
          </button>
        </div>

        {
          !(isMobile) && (
            <div className=" mx-auto flex items-center p-4 pl-1 h-12">
              <button onClick={openModal} className="flex items-center border hover:bg-neutral-100 py-2 px-4 border-black rounded-full">
                <svg viewBox="0 0 16 16" aria-hidden="true" role="presentation" focusable="false" className="block w-4 h-4" fill="currentColor"><path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg><div className="flex items-center">&nbsp;Filters</div>
              </button>
            </div>
          )
        }
      </div>



      <div className="HeroSearchForm2Mobile ">
        <Transition appear show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="HeroSearchFormMobile__Dialog relative z-max"
            onClose={closeModal}
          >
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out transition-transform"
                  enterFrom="opacity-0 translate-y-52"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in transition-transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-52"
                >
                  <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                    {showDialog && (
                      <Tab.Group manual>
                        <div className="absolute left-4 top-4">
                          <button className="" onClick={closeModal}>
                            <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                          </button>
                        </div>


                        <div className="flex-1 w-[70%] mx-auto pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                          <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                            <Tab.Panel>
                              <div className="transition-opacity mt-8 animate-[myblur_0.4s_ease-in-out]">
                                <HeroSearchForm className='text-black border-black'/>
                              </div>
                            </Tab.Panel>
                          </Tab.Panels>
                        </div>
                      </Tab.Group>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>

    </>
  )


}

export default ListingMapFilters;