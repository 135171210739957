import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PaymentSelection from "./components/payment";
import StaffSelection from './components/StaffSelection';
import DateAndTimeSelector from './components/DateAndTimeSelector';
import ConfirmBookingPage from './components/Booking';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Staff, TaxInfo, Service} from "../../types/types";

const OrderBooking = () => {
  const navigate = useNavigate();
  
  const [cartData, setCartData] = useState<{ business_id: number; cartItems: Service[] } | null>(null);
  const [discountTotal, setDiscountTotal] = useState<string>("");
  const [oldTotal, setOldTotal] = useState<string>("");
  const [cartTotal, setCartTotal] = useState<string>("0.00");
  const [taxInfo, setTaxInfo] = useState<TaxInfo | null>(null);

  const [backButtonProp, setbackButtonProp] = useState<String>("");
  const [continueButtonProp, setcontinueButtonProp] = useState<String>("disabled opacity-70");
  const [card, setCard] = useState<number>(0);
  const [selectedStaffInfo, setSelectedStaffInfo] = useState<Staff | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); 
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [paymentId, setPaymentId] = useState<string>("");
  const [paymentMethodSet, setPaymentMethodSet] = useState(false);
  const [confirmOrder, setconfirmOrder] = useState(false);
  const [CButtonText, setCButtonText] = useState('Continue');
  const [black, setBlack] = useState(false);

useEffect(() => {
  if (black) {
    setcontinueButtonProp("opacity-100");
  } else {
    setcontinueButtonProp("disabled opacity-70");
  }
}, [black]);

  const [isOrderSummaryVisible, setOrderSummaryVisible] = useState<boolean>(false); // New state

  const calculateTotal = () => {
    if (cartData) {
      const temp = cartData.cartItems.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
      return temp;
    }
    return '0.00';
  };

  useEffect(() => {
    setCartTotal(calculateTotal());
  }, [cartData]);

  useEffect(() => {
    const storedCartData = localStorage.getItem('cartData');
    const parsedCartData = storedCartData ? JSON.parse(storedCartData) : null;
    setCartData(parsedCartData);
  }, []);

  useEffect(() => {
    if (cartData && cartData.business_id) {
      axios.get(`/api/business/taxes/${cartData.business_id}`)
        .then((response) => {
          if (response.data.message === 'A') {
            setTaxInfo(response.data.data);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [cartData]);

  useEffect(() => {
    if (taxInfo) {
      const cartTotalWithoutGST = parseFloat(cartTotal);
      if (taxInfo.gst_percentage > 0) {
        const gstAmount = (cartTotalWithoutGST * taxInfo.gst_percentage) / 100;
        const newCartTotal = (cartTotalWithoutGST + gstAmount).toFixed(2);
        setCartTotal(newCartTotal);
      }
    }
  }, [taxInfo]);

  const handleCartTotal = (newCartTotal: string, discount: string) => {
    setOldTotal(cartTotal);
    setCartTotal(newCartTotal);
    setDiscountTotal("-" + discount);
  };

  const setStaff = (staff: Staff) => {
    setSelectedStaffInfo(staff);
  }

  const BackCard = () => {
    if(card === 0) {
      navigate(-1);
    } else {
      setCard(card - 1);
    }
  }

  const ContinueCard = () => {
    if(card === 0) {
      if(selectedStaffInfo) {
        setCard(1);
        setbackButtonProp("visible");
        setcontinueButtonProp("opacity-50 disabled");
      }
    } else if(card === 1) {
      if(selectedTime) {
        console.log("Setting card 2")
        if(cartData?.cartItems.length == 0) {
          console.log("Setting the card as 3")
          setCard(3)
          setcontinueButtonProp("opacity-50 disabled");
        }
        else {
          setCard(2);
          setcontinueButtonProp("opacity-50 disabled");
        }
      }
    } else if(card === 2) {
      
      if(paymentMethod) {
        setPaymentMethodSet(true);
        if(paymentMethod === 'payOnVisit') {
          setCButtonText('Confirm Order');
          setCard(3);
        }
      }
    } else if(card === 3) {
      console.log("button clicked")
      setconfirmOrder(true);
    }
  }

  useEffect(() => {
    if(selectedStaffInfo) {
      setcontinueButtonProp("");
    }
  }, [selectedStaffInfo]);

  const setTime = (time: string) => {
    setSelectedTime(time);
    setcontinueButtonProp("");
  }

  function setPayment(paymentMethod: string, paymentId: string): void {
    setPaymentId(paymentId);
    setPaymentMethod(paymentMethod);
    setcontinueButtonProp("");
  }

  function convertTo12HourFormat(time24: string): string {
    let [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  

  const toggleOrderSummary = () => setOrderSummaryVisible(!isOrderSummaryVisible); // New function

  useEffect(() => {
    if(selectedStaffInfo) {
      setCard(1)
    }
  }, [selectedStaffInfo])

  return (
    <>
      <div className="container flex justify-center min-h-[88vh]">
        <div className="fixed top-0 min-h-[88vh] h-full lg:h-[88vh] bg-white z-50 lg:z-0 w-full lg:mt-0 lg:relative lg:w-[70%] flex flex-col">
          <div className="flex-grow" style={{ flexBasis: '80%' }}>
            {card === 0 ? (
              <StaffSelection selectedStaff={selectedStaffInfo} businessId={cartData?.business_id} setStaff={setStaff} />
            ) : card === 1 ? (
              <DateAndTimeSelector bookingDate={selectedDate} selectedTime={selectedTime} businessId={cartData?.business_id} selectedStaff={selectedStaffInfo} selectDate={setSelectedDate} setSelectedTime={setTime} />
            ) : card === 2 && cartData && cartData.cartItems.length > 0 ? (
              <>
                {cartData && cartData.cartItems.length > 0 && (
                  <PaymentSelection
                      paymentMethodSet={paymentMethodSet}
                      cartTotal={cartTotal}
                      cartItems={cartData?.cartItems}
                      businessId={cartData?.business_id}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      selectedStaffInfo={selectedStaffInfo}
                      couponCode={null}
                      taxInfo={taxInfo}
                      onSelectPaymentMethod={setPayment}
                  />
                )}
              </>
            ) : card === 3 ? (
              <>
                {cartData && (
                  <ConfirmBookingPage
                      cartItems={cartData.cartItems}
                      businessId={cartData.business_id}
                      selectedDate={selectedDate}
                      selectedTime={selectedTime}
                      cartTotal={cartTotal}
                      selectedStaffInfo={selectedStaffInfo}
                      paymentId={paymentId}
                      paymentMethod={paymentMethod}
                      couponCode={null}
                      taxInfo={taxInfo}
                      handleClose={() => setCard(2)}
                      SendOtpEvent={confirmOrder}
                      changeSendOtpEvent={setconfirmOrder}
                      setBlack={setBlack}
                    />
                )}
              </>
            ) : (
              <div></div>
            )}
          </div>
          <div className="absolute bottom-0 w-full bg-gray-100 lg:bg-white py-2">
          {
                card == 0 ? (
                  <div className="text-center">Please select professional from top to continue </div>
                ) : card == 1 ? (
                  <div className="text-center">Please select appointment date & time to continue </div>
                ) : (
                  <></>
                )
              }
            <div className="px-8 w-full lg:pb-4 mx-auto lg:w-[70%] bg-gray-100 lg:bg-white py-4 justify-between items-center flex">
              
              <button onClick={BackCard} className={` ${backButtonProp} border py-2 w-[50%] mx-2 border-black bg-white rounded-md text-lg`}>Back</button>
              <button onClick={ContinueCard} className={` ${continueButtonProp} border py-2 w-[50%] mx-2 bg-black rounded-md text-white text-lg`}>{CButtonText}</button>
            </div>
            <div className="flex lg:hidden bg-white w-full px-4 py-4 border border-t-black" onClick={toggleOrderSummary}>
              <div className="w-[70%] flex flex-cols text-xl font-semibold">
                Total Items: {cartData?.cartItems.length} <br></br>
                Total: ₹{cartTotal}
              </div>
              <div className="w-[30%] bg-red-400 rounded-full  text-center flex items-center justify-center text-white py-1 px-4">
               Cart
              </div>
            </div>
          </div>
        </div>

        <div className={`p-4 text-center h-full lg:min-h-[60vh] my-auto bg-white border rounded-3xl drop-shadow-xl md:w-[40%] lg:w-[30%] fixed lg:relative z-50 lg:z-0 top-0 left-0 right-0 flex flex-col ${isOrderSummaryVisible ? 'block' : 'hidden'} lg:block`}>
          {/* Close button for mobile/tablet */}
          <button onClick={() => setOrderSummaryVisible(false)} className="lg:hidden self-end text-gray-600 hover:text-gray-900">
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <h2 className="text-center text-xl py-4 mx-auto">Order Summary</h2>
          <div className="overflow-auto flex-grow">
            {cartData && cartData.cartItems.length > 0 ? (
              <div>
                <div className="cartItem">
                  <div className="font-semibold">Service</div>
                  <div className="font-semibold">Price(₹)</div>
                </div>
                <ul>
                  {cartData.cartItems.map((item) => (
                    <div className="cartItem" key={item.id}>
                      <div>
                        {item.name}{' '}
                        <div className="font-thin text-gray-500">{item.duration} mins</div>
                      </div>
                      <div>{item.price}</div>
                    </div>
                  ))}
                </ul>
                <hr className="mt-4 mb-4" />
                {taxInfo && (
                  <div className="cartItem total">
                    <div className="text-gray-500 font-italic">GST</div>
                    <div className="text-gray-500 font-italic">{taxInfo.gst_percentage}%</div>
                  </div>
                )}
                {oldTotal ? (
                  <>
                    <div className="cartItem total">
                      <div className="font-semibold text-red-500 line-through">Total</div>
                      <div className="font-semibold text-red-500 line-through">{oldTotal}</div>
                    </div>
                    <div className="cartItem total">
                      <div className="font-semibold text-gray-500">Coupon Applied</div>
                      <div className="font-semibold text-gray-500">{discountTotal}</div>
                    </div>
                    <div className="cartItem total">
                      <div className="font-semibold text-green-600">Total</div>
                      <div className="font-semibold text-green-600">{cartTotal}</div>
                    </div>
                  </>
                ) : (
                  <div className="cartItem total">
                    <div className="font-semibold">Total</div>
                    <div className="font-semibold">{cartTotal}</div>
                  </div>
                )}
              </div>
            ) : (
              <div>No items in the cart</div>
            )}
          </div>

          <div>
            {selectedStaffInfo && (
              <>
                <h2 className="text-left my-2 text-md">Selected Professional</h2>
                <div className="flex border items-center rounded-full border-black">
                  <div className="flex rounded-full m-1">
                    <img className="h-16 w-16 rounded-full" src={`${selectedStaffInfo.profile}`} />
                  </div>
                  <div>
                    <div className="text-left flex font-bold text-lg mx-8">{selectedStaffInfo.name}</div>
                    <div className="text-left flex font-light mx-8">{selectedStaffInfo.job_title}</div>
                  </div>
                </div>
              </>
            )}
          </div>

          {selectedDate != null && selectedTime != '' && (
            <>
              <h2 className="text-center my-2 text-md">Appointment Date and Time</h2>
              <div className="flex items-center justify-center border rounded-full border-gray-300 px-4 py-2">
                <div className="date-display text-center text-gray-700 font-semibold mr-4">
                  {selectedDate.toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </div>
                <div className="time-display text-gray-800 font-semibold">
                  ({convertTo12HourFormat(selectedTime)})
                </div>
              </div>
            </>
          )}
        </div>
      </div>


      {/* Overlay for Mobile Order Summary */}
      {isOrderSummaryVisible && (
        <div className="fixed h-full bg-white inset-0 bg-black bg-opacity-50 z-40 lg:hidden" onClick={toggleOrderSummary}></div>
      )}
    </>
  );
};

export default OrderBooking;
