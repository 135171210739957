import React, { useState, useEffect } from 'react';
import axios from "axios";
import Invoice from './Invoice';
import { Link } from 'react-router-dom';
import {Service, Staff, paymentDetails, paymentResponse, TaxInfo} from "../../../types/types";


interface PaymentSelectionProps {
  cartTotal: string,
  cartItems: Service[];
  businessId: number;
  selectedDate: Date | null;
  selectedTime: string;
  selectedStaffInfo: Staff | null;
  couponCode: string | null;
  taxInfo: TaxInfo | null;
  onSelectPaymentMethod: (paymentMethod: string, paymentId: string) => void;
  paymentMethodSet: boolean;
}

const PaymentSelection: React.FC<PaymentSelectionProps> = ({
  cartTotal,
  cartItems,
  businessId,
  selectedDate,
  selectedTime,
  selectedStaffInfo,
  couponCode,
  taxInfo,
  onSelectPaymentMethod,
  paymentMethodSet

}) => {
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [orderDetails, setOrderDetails] = useState<paymentDetails | null>(null);
  const [userId, setUserId] = useState<string | null>("");

  const [paymentResponse, setPaymentResponse] = useState<paymentResponse | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);


  const [success, setSuccess] = useState(false);
  const [invoiceHTML, setInvoiceHTML] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [invoiceOTP, setInvoiceOTP] = useState<string>("");
  const [paymentId, setpaymentId] = useState<string>("");
  

  useEffect(() => {
    const readCookie = (cookieName: string): string | null => {
      const nameEQ = cookieName + "=";
      const cookies = document.cookie.split(";");
  
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
          return cookie.substring(nameEQ.length, cookie.length);
        }
      }
      return null;
    };
  
    const tempCookie = readCookie("userId");
    setUserId(tempCookie)
  }, [])


  const InitiatePayment = (userId: string) => {
    axios.post("/api/payment/order", {
      userId,
      cartTotal
    })
    .then(async (response) => {
      setOrderDetails(response.data);

      const razorpaySuccessCallback = (response: any) => {
        if (response.status_code === 200) {
          axios.post("/api/payment/success", {
            razorpay_order_id: response.razorpay_order_id,
            status_code: response.status_code
          }).then((res) => {
            setOrderId(response.razorpay_order_id);
            addAppointmentDetails(response.razorpay_order_id);
          })
        } else {
          alert("payment failed")
        }
      };
      
      const res = await loadRazorpay();

    if(!res) {
      alert('Razor pay SDK failed to load. Please check your connection')
    }
    else {
      var options = {
        "key": "rzp_live_hLLSWzmgB1jl4j",
        "amount": response.data.amount,
        "currency": response.data.currency,
        "name": "Feelaxo",
        "description": "Saloon booking",
        "image": "https://feelaxo.com/feelaxo.png",
        "order_id": response.data.orderId,
        "handler": razorpaySuccessCallback,
        "prefill": { 
            "name": response.data.name,
            "email": response.data.email,
            "contact": response.data.phone
        },
        "notes": {
            "address": "D-Mart Road, Kharghar, Navi Mumbai"
        },
        "theme": {
            "color": "#3399cc"
        }
    };
    const _window = window as any

    const paymentObject = new _window.Razorpay(options)
    paymentObject.open();
    }

    })
    .catch((error) => {
      console.log(error);
    })
  }








    function loadRazorpay() {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        document.body.appendChild(script);
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
      });
    }




    const addAppointmentDetails = (orderId: string) => {
     if(paymentId != null) {
      axios.post("/api/order/online", {
        cartTotal,
        cartItems,
        businessId,
        selectedDate,
        selectedTime,
        selectedStaffInfo,
        couponCode,
        taxInfo,
        paymentMethod: "online",
        orderId: orderId,
        userId,
      }).then((response) => {

        setInvoiceHTML(response.data.html);
        setInvoiceOTP(response.data.OTP);
        setPhoneNumber(response.data.phone);
        setpaymentId(response.data.orderId);

        setSuccess(true);

        
      }).catch((error) => {
        console.log(error);
      })
     }
    }

    const handlePaymentSelection = () => {
      
      if (paymentMethod === 'payOnline') {
        if (userId != null) {
          InitiatePayment(userId)
        }
      }
      
    };


    useEffect(() => {

      if(paymentMethodSet) {
        handlePaymentSelection();
      }

    }, [paymentMethodSet])
  
    const generateUniqueNumber = () => {
      const randomNumber = Math.floor(Math.random() * 1000000).toString();
      const timestamp = new Date().getTime().toString();
  
      return `${timestamp}${randomNumber}`;
    };

    function setPaymentInfo(method: string): void {
       if(method == 'payOnVisit') {
        let paymentId = '';
        setPaymentMethod(method)
        paymentId = `POV-${generateUniqueNumber()}`;
        onSelectPaymentMethod(method, paymentId);
       }
       else {
        setPaymentMethod(method)
        onSelectPaymentMethod(method, '');
       }
    }

    

  return (
    <>
    <div className="max-w-md mx-auto p-6 mt-24 bg-white rounded-md border">
      <h2 className="text-lg font-semibold mb-4">Select Payment Method</h2>

      

      <div className="flex w-full items-center mb-4">
        <input
          type="radio"
          id="payOnVisit"
          name="paymentMethod"
          value="payOnVisit"
          className="mr-2"
          onChange={() => setPaymentInfo('payOnVisit')}
        />
        <label htmlFor="payOnVisit" className="cursor-pointer">
          <div className="flex w-96 items-center bg-gray-100 p-3 rounded-md">
            <span className="text-green-500 mr-2">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path
                  d="M5 13l4 4L19 7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <span className="font-semibold">Pay on Visit</span>
          </div>
        </label>
      </div>

      <div className="flex w-full items-center">
        <input
          type="radio"
          id="payOnline"
          name="paymentMethod"
          value="payOnline"
          className="mr-2"
          onChange={() => setPaymentInfo('payOnline')}
        />
        <label htmlFor="payOnline" className="cursor-pointer">
          <div className="flex w-96 items-center bg-gray-100 p-3 rounded-md">
            <span className="text-blue-500 mr-2">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path 
                  d="M5 13l4 4L19 7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
            <span className="font-semibold">Pay Online</span>
          </div>
        </label>
      </div>

      <div className={`my-4 flex mx-auto items-center justify-center w-96`}>
                      <div className="font-semibold text-center text-xl">Total - ₹{cartTotal}</div>
                      {/* <div className="font-semibold">₹{cartTotal}</div> */}
                    </div>
                    <p className='text-xs text-center mb-5'>(total is inclusive of all taxes and discount). <br></br>By clicking the button below you are accepting our <br></br><Link to="/TermsCondition" className="font-bold text-blue-400">terms and conditions</Link>.</p>
      </div>

      {
        success && (
          <Invoice paymentId={paymentId} htmlString={invoiceHTML} phone={phoneNumber} otp={invoiceOTP} />
        )
      }


      </>
  );
};

export default PaymentSelection;